import TableCell from "../../../UIComponents/DesignSystem/TableCell.js";
import TableBody from "../../../UIComponents/DesignSystem/TableBody.js";
import TableRow from "../../../UIComponents/DesignSystem/TableRow.js";
import Table from "../../../UIComponents/DesignSystem/Table.js";
import TableHeadComponent from "./TableHeader.js";
import { Stack, Typography } from "../../../UIComponents/index.js";
import { styled } from "@mui/material";
import IconButton from "../../../UIComponents/DesignSystem/IconButtton.js";
import AddIcon from "@mui/icons-material/Add";
import { useContext } from "react";
import { ModalContext } from "./Dashboard.js";

const StyledStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.secondaryLight.main,
}));

const CertificationDetails = ({
  userId,
  title,
  headerColMap,
  certification_data = [],
  showAssignIcon = false,
}) => {
  const { handleAssignCertModalOpen } = useContext(ModalContext);
  return (
    <StyledStack gap={1} paddingTop={1} paddingBottom={2} paddingX={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography fontSize="16px" fontWeight={600}>
          {title}
        </Typography>
        {showAssignIcon && (
          <IconButton
            color="primary"
            variant="outlined"
            size="small"
            onClick={() => handleAssignCertModalOpen(userId)}
          >
            <AddIcon />
          </IconButton>
        )}
      </Stack>
      <Table aria-label="resources">
        <TableHeadComponent columns={Object.values(headerColMap)} indent={0} />
        <TableBody>
          {certification_data?.length === 0 && (
            <TableRow>
              <TableCell
                colSpan={13}
                style={{ textAlign: "center", height: 100 }}
              >
                No Records found!
              </TableCell>
            </TableRow>
          )}
          {certification_data?.map((certification) => {
            return (
              <TableRow>
                {Object.keys(headerColMap).map((colKey) => (
                  <TableCell style={{ textAlign: "center" }}>
                    {certification[colKey] ?? "-"}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </StyledStack>
  );
};

export default CertificationDetails;
