
import { styled } from "@mui/material";
import { TableBody as TableBodyComp } from "../index.js"; 

const StyledTableBody = styled(TableBodyComp)(({ theme }) => ({
}));

const TableBody = (props) => {
    return ( 
        <StyledTableBody {...props} />
     );
}
 
export default TableBody;