import { styled } from "@mui/material";
import { Button, Typography } from "../../../UIComponents";
import { useSelector } from "react-redux";
import { preventCustomPropForwarding } from "../../../Utils/MUI";
import { useContext } from "react";
import { MeetingLocalStateContext } from "../DecisionTrackerMeeting";


const StyledNoteButton = styled(Button, preventCustomPropForwarding(['currentMeetingNote', 'active']))(({ currentMeetingNote, active, theme }) => ({
    justifyContent: "flex-start",
    width: "max-content",
    padding: '4px 8px',
    margin: '2px 0',
    backgroundColor: active && `${theme.palette.secondary.light} !important`,
    border: `1px solid ${currentMeetingNote ? theme.palette.secondary.main : 'transparent'}`,
  }));

const DeliverableNoteHeader = ({ note_id, selectedNoteId, meeting_id, handleSelectedNoteChange, workspace_id, deliverable_id }) => {
    const { meetingTitle: current_meeting_title } = useContext(MeetingLocalStateContext)
    const deliverable_note = useSelector(state => state.decisions.deliverables_notes[workspace_id]?.[deliverable_id]?.[note_id]) || {};
    const  { meeting_id: current_note_meeting_id } = deliverable_note;
    const meeting_title = useSelector(state => state.decisions.meetings[workspace_id]?.[current_note_meeting_id]?.title);
    const isCurrentMeetingNote = current_note_meeting_id === Number(meeting_id);


    return ( 
        <StyledNoteButton
            color='secondary'
            active={Number(note_id) === Number(selectedNoteId)}
            currentMeetingNote={isCurrentMeetingNote}
            onClick={() => handleSelectedNoteChange({ deliverable_note })}>
            <Typography noWrap>#{note_id}-{isCurrentMeetingNote ? current_meeting_title : meeting_title}-note</Typography>
        </StyledNoteButton> 
    );
}
 
export default DeliverableNoteHeader;