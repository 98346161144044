import styled from '@emotion/styled';
import { Typography as TypographyComp } from '../index.js';
import { forwardRef } from 'react';

const StyledTypography = styled(TypographyComp)(({ theme, color }) => ({
    color: color && theme.palette[color].main,
}));

const Typography = forwardRef(({ color, ...rest }, ref) => {
    return ( <StyledTypography ref={ref} color={color} {...rest} /> );
})
 
export default Typography;