import { useEffect, useState } from "react";
import { TimeUtilization } from "../TimeEntrySummary/TimeUtilization";
import { Stack, Typography } from "../../UIComponents";
import { getUtilizationChartDataAPI } from "../../apis/Dashboards";

const CustomTimeUtilization = (props) => {
  const { userIds, startDate, endDate } = props;
  const [utilizationData, setUtilizationData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const fetchUtilizationChartData = async ({ member_ids }) => {
      try {
        setIsLoading(true);
        const chart_data = await getUtilizationChartDataAPI({
          startDate,
          endDate,
          member_ids,
        });
        setIsLoading(false);
        setUtilizationData(chart_data);
      } catch (err) {
        setIsLoading(false);
        console.log(err);
      }
    };
    fetchUtilizationChartData({ member_ids: userIds });
  }, [endDate, startDate, userIds]);

  let childComponent = <TimeUtilization data={utilizationData} />;

  if (isLoading) childComponent = <Typography>...Loading</Typography>;
  return (
    <Stack padding={2} justifyContent="center" alignItems="center">
      {childComponent}
    </Stack>
  );
};

export default CustomTimeUtilization;
