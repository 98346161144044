import { Button, Typography } from "../../../UIComponents";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useDispatch, useSelector } from "react-redux";
import { createNewMeeting } from "../../../redux/reducers/decisions";



const CreateNewMeetingButton = ({ workspace_id }) => {
    const dispatch = useDispatch();
    const { isLoading } = useSelector(state => state.apiStatus['decisions/createNewMeeting']) || {};

    const handleCreateNewMeeting = () => {
        dispatch(createNewMeeting({ workspace_id }))
    }
    return ( 
        <Button 
            variant="contained" 
            color="primary" 
            size="large" 
            startIcon={<AddCircleOutlineIcon />} 
            onClick={handleCreateNewMeeting}
            disabled={isLoading}
        >
            <Typography>
                Create New Meeting
            </Typography>
        </Button>
     );
}
 
export default CreateNewMeetingButton;