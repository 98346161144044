/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import {
  ButtonGroup,
  CircularProgress,
  Paper,
} from "@mui/material";
import TableRow from "../../UIComponents/DesignSystem/TableRow";
import TableCell from "../../UIComponents/DesignSystem/TableCell";
import Table from "../../UIComponents/DesignSystem/Table";
import TableContainer from "../../UIComponents/DesignSystem/TableContainer";
import TableHead from "../../UIComponents/DesignSystem/TableHead";
import TableBody from "../../UIComponents/DesignSystem/TableBody";
import {
  Button,
  Stack
} from "../../UIComponents";
import { useDispatch, useSelector } from "react-redux";
import WorkspaceRow from "./WorkspaceRow";
import { getAllProjects } from "../../redux/reducers/workspaces";

const getFilteredWorkspaces = ({ workspaces_arr, workspaceFilter }) =>
  workspaces_arr.filter(([_, { archived }]) =>
    {
      if(workspaceFilter === 'active_filter' && archived === false ) return true;
      else if(workspaceFilter === 'all_filter') return true;
      return false;
    }
  );

const WorkspacesTable = () => {

  const dispatch = useDispatch();

  const { isLoading, isSuccess, error } = useSelector(state => state.apiStatus['projects/getAllProjects']) || {};
  const workspaces = useSelector((state) => state.workspaces.all);
  const workspaces_arr = useMemo(() => Object.entries(workspaces),[workspaces]);
  const [workspaceFilter, setWorkspaceFilter] = useState("active_filter");

  

const filteredWorkspacesArr = useMemo(() => getFilteredWorkspaces({ workspaces_arr, workspaceFilter }), [workspaces_arr, workspaceFilter]);

  useEffect(() => {
    dispatch(getAllProjects());
  }, []);


    return ( 
        <TableContainer component={Paper}>
          <Stack p={2}>
            <ButtonGroup size="medium">
              <Button color={workspaceFilter === 'active_filter' ? 'primary' : 'secondary'} onClick={() => setWorkspaceFilter('active_filter')}>Active</Button>
              <Button color={workspaceFilter === 'all_filter' ? 'primary' : 'secondary'} onClick={() => setWorkspaceFilter('all_filter')}>All</Button>
            </ButtonGroup>
          </Stack>
          <Table>
            <TableHead>
              <TableRow className="t-h-row">      
                <TableCell size="verysmall">Project Name</TableCell>
                <TableCell size="verysmall">Status</TableCell>
                <TableCell size="verysmall">Due Date</TableCell>
                <TableCell size="verysmall">Percentage Complete</TableCell>
                <TableCell size="verysmall">Total Budgeted Hours</TableCell>
                <TableCell size="verysmall">Used Budgeted Hours</TableCell>
                <TableCell size="verysmall">This Month Utilised Hours</TableCell>
                <TableCell size="verysmall">This Week Utilised Hours</TableCell>
                <TableCell size="verysmall">Percentage of Budget Used</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {isLoading && !workspaces_arr.length && (
                <TableRow>
                  <TableCell
                    colSpan={10}
                    style={{ textAlign: "center", height: 300 }}
                  >
                    <CircularProgress color="primary" />
                  </TableCell>
                </TableRow>
              )}
              {error && (
                <TableRow>
                  <TableCell
                    colSpan={10}
                    style={{ textAlign: "center", height: 300 }}
                  >
                    Error in loading the data! Please try again later.
                  </TableCell>
                </TableRow>
              )}
              {isSuccess && workspaces_arr.length === 0 && (
                <TableRow>
                  <TableCell
                    colSpan={10}
                    style={{ textAlign: "center", height: 300 }}
                  >
                    No Records found!
                  </TableCell>
                </TableRow>
              )}

              { Boolean(filteredWorkspacesArr.length) &&
                filteredWorkspacesArr.map(([workspace_id, workspace_data]) => (
                  <WorkspaceRow
                    key={workspace_id}
                    workspace={workspace_data}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
     );
}
 
export default WorkspacesTable;