import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getDeliverablesAPI,
  getWorkspaceResourcesAPI,
  getWrokspacesAPI,
} from "../../apis/Workspace";

export const getAllProjects = createAsyncThunk(
  "projects/getAllProjects",
  async (_, { dispatch }) => {
    const reports = await getWrokspacesAPI();
    dispatch(updateAllProjects({ reports }));
  }
);

export const getDeliverablesByWorkspace = createAsyncThunk(
  "workspaces/getDeliverablesByWorkspace",
  async ({ workspace_id }, { dispatch }) => {
    const deliverables = await getDeliverablesAPI({ workspace_id });
    dispatch(updateDeliverables({ workspace_id, deliverables }));
  }
);

export const getWorkspaceResources = createAsyncThunk(
  "workspaces/getDeliverablesByWorkspace",
  async ({ workspace_id }, { dispatch }) => {
    const workspace_resources = await getWorkspaceResourcesAPI({
      workspace_id,
    });
    const internal_workspace_resources = Object.fromEntries(
      Object.entries(workspace_resources).filter(([_, value]) => value.role_id)
    );
    const workspace_resources_ids = Object.keys(internal_workspace_resources);
    console.log({ workspace_resources_ids })
    dispatch(
      updateWorkspaceResources({
        workspace_resources_ids: workspace_resources_ids,
        workspace_resources: internal_workspace_resources,
        workspace_id,
      })
    );
    return { ids: workspace_resources_ids, data: internal_workspace_resources };
  }
);

const initialState = {
  all: {},
  deliverables: {},
  resources: {},
};
export const workspacesSlice = createSlice({
  name: "workspaces",
  initialState,
  reducers: {
    updateAllProjects: (state, { payload }) => {
      const { reports } = payload;
      state.all = {
        ...state.all,
        ...reports,
      };
    },
    updateDeliverables: (state, { payload }) => {
      const { workspace_id, deliverables } = payload;
      state.deliverables[workspace_id] = deliverables;
    },
    updateWorkspaceResources: (state, { payload }) => {
      const { workspace_resources, workspace_resources_ids, workspace_id } =
        payload;
      const mentionList = Object.values(workspace_resources).map(({ full_name }, index) => ({id: index, value: full_name.replace(' ', '')}));

      state.resources = { 
        ...state.resources,
        [workspace_id]: {
          ids: workspace_resources_ids,
          entities: workspace_resources,
          mentionList
        }
      }
    },
  },
});

const { actions, reducer } = workspacesSlice;

export const {
  updateAllProjects,
  updateDeliverables,
  updateWorkspaceResources,
} = actions;

export default reducer;
