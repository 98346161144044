import { combineReducers } from "@reduxjs/toolkit";
import managerSlice from "./managers.js";
import leadsReducer from "./leads.js";

const membersReducer = combineReducers({
  managers: managerSlice,
  leads: leadsReducer,
});

export default membersReducer;
