/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from "dayjs";
import { Paper, Stack, TableRow } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import NonBillableTimeEntry from "./NonBillableTimeEntry";
import ControlBar from "./ControlBar";
import PendingIcon from "@mui/icons-material/Pending";
import {
  StyledImg,
  StyledPopover,
  StyledTableCell,
  StyledTableRow,
  StyledTableWrapper,
  StyledTypography,
  StyledVisibleWeekCell,
  SytledTeammateCell,
} from "./styledComponents";
import ProgressBarWithText from "../../../ComponentsLibrary/ProgressBarWithText";
import { formatNumber } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllUsers,
  getTargetMembers,
  getUsersTimeData,
} from "../../../redux/reducers/users";
import { features } from "../../../apis/User/TeamMembers";
import { useAuth } from "../../../CustomHooks/AuthenticationHook";
import TableHead from "../../../UIComponents/DesignSystem/TableHead";
import TableContainer from "../../../UIComponents/DesignSystem/TableContainer";
import { Table } from "react-bootstrap";
import TableBody from "../../../UIComponents/DesignSystem/TableBody";
import TableCell from "../../../UIComponents/DesignSystem/TableCell";

export const getVisibleWeekObject = ({ startDate, offset = 0 }) => {
  const sow = startDate.add(offset, "week");
  const eow = sow.endOf("week").subtract(1, "day");
  const key = `${sow.format("YYYY/MM/DD")}-${eow.format("YYYY/MM/DD")}`;
  const title = [sow.format("DD MMM"), eow.format("DD MMM")].join(" - ");
  return {
    key,
    title,
    sow,
    eow,
  };
};

const getVisibleWeeks = ({ startDate }) => {
  const visibleWeekArray = [];
  for (let i = 0; i < 8; i++) {
    const visibleWeekObject = getVisibleWeekObject({ startDate, offset: i });
    visibleWeekArray.push(visibleWeekObject);
  }
  return visibleWeekArray;
};
/*** @TO-DO: these users are failing the get_users_non_billable api, hence filtering them out */
export const filterUsers = [50, 52, 53, 54, 57, 60, 61, 62, 63, 75];

export const allowEditingAllUsers = [29, 74, 3];

const defaultUserPhoto =
  "https://app.mavenlink.com/images/default_profile_photo/default.png";

const NonBillableTable = () => {
  const dispatch = useDispatch();
  const { user: { userId } } = useAuth();
  const superUser = allowEditingAllUsers.includes(userId);
  const usersTimeData = useSelector((state) => state.users.timeData);
  const allUsersMap = useSelector((state) => state.users.all);
  const targetMembers =
    useSelector(
      (state) =>
        state.users.targetMembersByFeature[features.update_non_billable]
    ) || [];
  const targetMemberIds = useMemo(
    () =>
      targetMembers?.filter((id) => !filterUsers.includes(Number(id))) || [],
    [targetMembers]
  );

  const allUsers = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(allUsersMap).filter(
          ([userId]) => !filterUsers.includes(Number(userId))
        )
      ),
    [allUsersMap]
  );
  const allMembersIds = useMemo(() => Object.keys(allUsers), [allUsers]);
  const allUsersLength = Object.keys(allUsers).length;
  const reportees = useMemo(() =>
    superUser ? allMembersIds : targetMemberIds
  );
  const [selectedCell, setSelectedCell] = useState({
    anchorEl: null,
    sow: null,
    data: null,
    key: "",
    weekTitle: "",
  });

  useEffect(() => {
    if (!Object.keys(allUsers).length) {
      dispatch(getAllUsers());
      dispatch(
        getTargetMembers({ userId, featureId: features.update_non_billable })
      );
    }
  }, [dispatch, allUsers, userId]);

  useEffect(() => {
    if (
      (superUser ? allMembersIds.length : targetMemberIds.length) &&
      !Object.keys(usersTimeData).length
    ) {
      const allowedAllUserProps = superUser
        ? { givenUserIds: allMembersIds }
        : undefined;
      dispatch(getUsersTimeData(allowedAllUserProps));
    }
  }, [allUsers, usersTimeData, dispatch]);

  const [startDate, setStartDate] = useState(
    dayjs().startOf("week").add(1, "day")
  );
  const visibleWeeks = useMemo(
    () => getVisibleWeeks({ startDate }),
    [startDate]
  );

  const handlePopoverClick = ({
    event: { currentTarget },
    sow,
    eow,
    data,
    key,
    weekTitle,
  }) => {
    setSelectedCell({
      anchorEl: currentTarget,
      sow,
      eow,
      data,
      key,
      weekTitle,
    });
  };
  const handlePopoverClose = () => {
    setSelectedCell({
      anchorEl: null,
      sow: null,
      data: null,
      eow: null,
      key: null,
      weekTitle: null,
    });
  };
  return (
    <Stack gap={2}>
      {/* Control Bar  */}
      <ControlBar
        setStartDate={setStartDate}
        allMembersIds={superUser && allMembersIds}
      />
      {/** Table */}
      <TableContainer component={Paper}>
        <Table aria-label="Projects grouped by managers">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <Stack
                  justifyContent="flex-start"
                  alignItems="center"
                  direction="row"
                  sx={{ height: "100%" }}
                >
                  <StyledTypography fontWeight={600}>
                    Teammates
                  </StyledTypography>
                </Stack>
              </StyledTableCell>
              {visibleWeeks.map(({ title }) => (
                <StyledTableCell key={title} height="30px">
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    direction="row"
                    sx={{ height: "100%" }}
                  >
                    <StyledTypography fontWeight={600}>
                      {title}
                    </StyledTypography>
                  </Stack>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {allUsersLength && Object.keys(usersTimeData).length ? (
              Object.entries(usersTimeData).map(([member_id, userTimeData]) => {
                if (!allUsers[member_id]) return null;
                const { name, photo } = allUsers[member_id];
                return (
                  <StyledTableRow>
                    <StyledTableCell>
                      <SytledTeammateCell>
                        <StyledImg
                          src={photo || defaultUserPhoto}
                          alt={`${name}'s pic`}
                        />
                        <StyledTypography>{name}</StyledTypography>
                      </SytledTeammateCell>
                    </StyledTableCell>
                    {visibleWeeks.map(({ key, title, sow, eow }) => {
                      const {
                        billable_hours_target,
                        billable_hours,
                        holiday_pto_hours = 0,
                        non_billable_hours_approved,
                      } = userTimeData[key] || {};
                      const total_working_hours =
                        40 - Number(holiday_pto_hours);
                      let cellContent = (
                        <StyledVisibleWeekCell>
                          <PendingIcon />
                        </StyledVisibleWeekCell>
                      );
                      if (billable_hours_target) {
                        cellContent = (
                          <StyledVisibleWeekCell
                            key={key}
                            style={{
                              cursor: "pointer",
                            }}
                            active={+(selectedCell.key === `${name}-${key}`)}
                            onClick={(e) => {
                              if (!billable_hours_target) return;
                              handlePopoverClick({
                                event: e,
                                key: `${name}-${key}`,
                                weekTitle: title,
                                sow,
                                eow,
                                data: userTimeData[key],
                              });
                            }}
                          >
                            <StyledTypography>
                              {non_billable_hours_approved} /{" "}
                              {total_working_hours}
                            </StyledTypography>
                            <ProgressBarWithText
                              value={formatNumber(billable_hours) + "h"}
                              maxValue={billable_hours_target + "h"}
                              minValue={"0h"}
                              color="secondary"
                            />
                          </StyledVisibleWeekCell>
                        );
                      }
                      return <StyledTableCell>{cellContent}</StyledTableCell>;
                    })}
                  </StyledTableRow>
                );
              })
            ) : reportees.length ? (
              <StyledTableRow>
                <TableCell colSpan={9}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <PendingIcon />
                    <StyledTypography>Loading Member Data...</StyledTypography>
                  </Stack>
                </TableCell>
              </StyledTableRow>
            ) : (
              <StyledTableRow>
                <TableCell colSpan={9}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <StyledTypography>
                      No direct reportee found
                    </StyledTypography>
                  </Stack>
                </TableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <StyledTableWrapper>
        <StyledPopover
          open={!!selectedCell.anchorEl}
          anchorEl={selectedCell.anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <NonBillableTimeEntry
            selectedCell={selectedCell}
            onClose={handlePopoverClose}
          />
        </StyledPopover>
      </StyledTableWrapper>
    </Stack>
  );
};

export default NonBillableTable;
