/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "../../UIComponents";
import { publishMeetingNotesThunk } from "../../redux/reducers/decisions";
import {
  getNoteSummaryString,
  getUserTags,
  returnTextIfPresent,
} from "./CopyMeetingNotes";
import { useMemo } from "react";
import UploadIcon from "@mui/icons-material/Upload";

import html2md from "html-to-md";
import Button from "../../UIComponents/DesignSystem/Button";

export const getMeetingSummary = ({
  current_meeting_id,
  title,
  total_hours,
  total_utlised_hours,
  this_month_budgeted_hours,
  this_week_budgeted_hours,
  agenda,
  general_notes,
  magna_next_steps,
  client_next_steps,
  deliverables,
  note_owners_tags,
  allUsersMap
}) => {
  const current_meeting_deliverbale_note_map = deliverables.map(
    ([deliverableId, deliverableNotesMap]) => {
      let deliverable_note = {};
      const deliverable_notes = Object.values(deliverableNotesMap).filter(
        (note) => note.meeting_id === current_meeting_id
      );
      if (deliverable_notes.length) {
        const note_owners = deliverable_notes[0]?.note_owners;
        const note_owners_tags = getUserTags({
          userIdArr: note_owners,
          allUsersMap,
        });
        const note_content = returnTextIfPresent({
          variable: note_owners.length,
          formattedTextTemplate: `<b>Note Owners</b>
        ${note_owners_tags.map((tag) => `${tag} `)} <br/>`,
        }) + deliverable_notes[0]?.note_content;
        deliverable_note = {
          note_content,
          post_id: deliverable_notes[0]?.post_id,
          note_id: deliverable_notes[0]?.note_id
        };
      }
      return [deliverableId, deliverable_note];
    }
  );

  let deliverable_notes_summary_map = {};

  current_meeting_deliverbale_note_map.forEach(
    ([deliverable_id, { note_content: note_content_HTML, post_id, note_id }]) => {
      deliverable_notes_summary_map[deliverable_id] = {
        note_content: html2md(note_content_HTML),
        post_id,
        note_id
      };
    }
  );

  const meeting_note_summary = html2md(
    getNoteSummaryString({
      title,
      total_utlised_hours,
      total_hours,
      this_month_budgeted_hours,
      this_week_budgeted_hours,
      agenda,
      general_notes,
      magna_next_steps,
      client_next_steps,
      note_owners_tags,
    })
  );

  return {
    meeting_note_summary,
    deliverable_notes_summary_map,
  };
};

const PublishMeetingNotes = ({
  workspace_id,
  meeting_id,
  isSaveButtonDisabled,
}) => {
  const dispatch = useDispatch();

  const current_workspace = useSelector(
    (state) => state.workspaces.all[workspace_id]
  );
  const allUsersMap = useSelector((state) => state.users.all);
  const {
    title,
    budgeted_hours,
    used_budgeted_hours,
    this_month_budgeted_hours = 0,
    this_week_budgeted_hours = 0,
  } = current_workspace || {};

  const current_meeting = useSelector(
    (state) => state.decisions.meetings[workspace_id]?.[meeting_id]
  );
  const {
    magna_next_steps = "",
    client_next_steps = "",
    general_notes = "",
    agenda = "",
    post_id,
    note_owners = [],
  } = current_meeting || {};

  const note_owners_tags = getUserTags({ allUsersMap, userIdArr: note_owners });

  const allNotedDeliverablesForThisWorkspace =
    useSelector((state) => state.decisions.deliverables_notes[workspace_id]) ||
    {};
  const listOfNotedDeliverables = useMemo(
    () =>
      Object.entries(allNotedDeliverablesForThisWorkspace).filter(
        ([_, deliverables]) =>
          Object.values(deliverables).some(
            ({ meeting_id: meetingId }) => meetingId === meeting_id
          )
      ),
    [allNotedDeliverablesForThisWorkspace, meeting_id]
  );

  const handlePublishMeetingNotes = (e) => {
    e.stopPropagation(); /** Stopping propogation so btn does not toggle Accordian */
    const { meeting_note_summary, deliverable_notes_summary_map } =
      getMeetingSummary({
        current_meeting_id: meeting_id,
        title,
        total_hours: budgeted_hours,
        total_utlised_hours: used_budgeted_hours,
        this_month_budgeted_hours,
        this_week_budgeted_hours,
        agenda,
        general_notes,
        magna_next_steps,
        client_next_steps,
        deliverables: listOfNotedDeliverables,
        note_owners_tags,
        allUsersMap,
      });
    dispatch(
      publishMeetingNotesThunk({
        meeting_id,
        workspace_id,
        post_id,
        meeting_note_summary,
        deliverable_notes_summary_map,
      })
    );
  };
  return (
    <Button
      startIcon={<UploadIcon />}
      color="primaryLight"
      variant="contained"
      size="small"
      disabled={!isSaveButtonDisabled}
      onClick={(e) => handlePublishMeetingNotes(e)}
    >
      {post_id ? (
        <Typography>Update Mavenlink Post</Typography>
      ) : (
        <Typography>Post to Mavenlink</Typography>
      )}
    </Button>
  );
};

export default PublishMeetingNotes;
