import React from "react";
import { AuthProvider } from "./CustomHooks/AuthenticationHook";
import AuthWrapper from "./Routes/AuthWrapper";
import "react-quill/dist/quill.snow.css";

function App() {
  return (
    <AuthProvider>
      <AuthWrapper />
    </AuthProvider>
  );
}

export default App;
