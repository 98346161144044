import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import {
  assignCertificationAPI,
  getCertificationDashboardAPI,
} from "../../apis/Dashboards";

const certificationAdapter = createEntityAdapter({
  selectId: (certification) => certification.id,
  //   sortComparer: (a, b) => a.deadline.localeCompare(b.deadline),
});

const initialState = certificationAdapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchCertificationDashboard = createAsyncThunk(
  "certificationDashboard/fetchCertificationDashboard",
  async (_, { rejectWithValue }) => {
    try {
      const data = await getCertificationDashboardAPI();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const assignCertification = createAsyncThunk(
  "userCertifications/assignCertification",
  async (
    { deadline, member_id, certification_id, notes = null, assigned_by_id },
    { rejectWithValue }
  ) => {
    try {
      const [certificationData] = await assignCertificationAPI({
        deadline,
        member_id,
        certification_id,
        notes,
        assigned_by_id,
      });
      return { ...certificationData, member_id };
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to assign certification"
      );
    }
  }
);

const certificationDashboardSlice = createSlice({
  name: "certificationDashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCertificationDashboard.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCertificationDashboard.fulfilled, (state, action) => {
        state.status = "succeeded";
        certificationAdapter.setAll(state, action.payload);
      })
      .addCase(fetchCertificationDashboard.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch certification data";
      })
      .addCase(assignCertification.pending, (state) => {
        state.status = "loading";
      })
      .addCase(assignCertification.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { member_id, ...certification } = action.payload;
        if (!state.entities[member_id]) {
          state.entities[member_id] = {
            assigned_certificates: [],
          };
        }

        state.entities[member_id].assigned_certificates.push(certification);
      })
      .addCase(assignCertification.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default certificationDashboardSlice.reducer;

export const {
  selectIds: selectAllCertificationIds,
  selectAll: selectAllCertifications,
  selectById: selectCertificationById,
} = certificationAdapter.getSelectors((state) => state.userCertifications);
