import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";

import moment from "moment";
import axios from "axios";
import { url } from "../config";
import CommentCard from "../DelivarablesDashboard/CommentCard";
import InputSubmitSectionComponent from "../ComponentsLibrary/InputSubmitSectionComponent";

const ProjectComments = (props) => {
  const { monthOptions, mgrOptions, projectData } = props;
  const [loading, setLoading] = useState(false);
  const [recentComments, setRecComments] = useState([]);

  const onSubmit = (commentData) => {
    setLoading(true);
    const data = {
      workspace_id: projectData.project_id,
      activity: commentData.comment,
      assignee_id: "",
      assignee_name: commentData.manager,
      month: commentData.month,
    };

    axios
      .post(`${url}/forecast/comments/${projectData.project_id}`, data)
      .then((res) => {
        if (res.status) {
          setLoading(false);
          fetchComments();
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const fetchComments = useCallback(() => {
    axios
      .get(`${url}/forecast/comments/${projectData.project_id}`)
      .then((res) => {
        if (res.status) {
          setRecComments(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, [projectData.project_id]);

  useEffect(() => {
    if (projectData.project_id) {
      fetchComments();
    }
  }, [projectData.project_id, fetchComments]);

  const sortCommentsByMonth = useMemo(() => {
    if (recentComments?.length === 0) {
      return [];
    }
    const groupedComments = recentComments.reduce((res, com) => {
      let monthYr = com.Month_Year.replace(" ", "");
      if (!res[`${monthYr}`]) {
        res[`${monthYr}`] = {
          monthYear: `${com.Month_Year}`,
          month: com.month,
          year: com.year,
          comments: [],
        };
      }
      res[`${monthYr}`].comments.push(com);
      return res;
    }, {});
    return Object.values(groupedComments);
  }, [recentComments]);

  const onEdit = (editedComment) => {
    const data = {
      comment_id: editedComment.comment_id,
      workspace_id: projectData.project_id,
      activity: editedComment.comment,
    };
    setLoading(true);
    axios
      .put(`${url}/forecast/comments/${projectData.project_id}`, data)
      .then((res) => {
        if (res.status) {
          setLoading(false);
          fetchComments();
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const onDelete = (comment_id) => {
    axios
      .delete(
        `${url}/forecast/comments/${projectData.project_id}/${comment_id}`
      )
      .then((res) => {
        if (res.status) {
          fetchComments();
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Grid container gap={1} sx={{ padding: 2, maxWidth: "50vw",mt:4 }}>
      <Grid item xs={12}>
        <Typography variant="h5" fontSize="18px" gutterBottom>
          {projectData.project_title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <InputSubmitSectionComponent
          optionsEnabled={["month", "manager"]}
          monthOptions={monthOptions}
          managerOptions={mgrOptions}
          onSubmitComment={onSubmit}
          loading={loading}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Recent Updates
        </Typography>
        <Grid container className="com-container">
          {sortCommentsByMonth.length === 0 && (
            <Typography variant="body2">No Updates found!</Typography>
          )}
          {sortCommentsByMonth.map((month, i) => (
            <Fragment>
              <Typography variant="body2" sx={{ fontSize: "1rem" }}>
                {`${moment()
                  .month(month.month - 1)
                  .format("MMMM")} ${month.year}`}
              </Typography>
              <Grid
                item
                key={`month-i-${i}`}
                xs={12}
                sx={{ padding: 1 }}
                className="com-container"
              >
                {month.comments.map((activity, idx) => (
                  <CommentCard
                    key={`com-i-${idx}`}
                    comment={activity}
                    actions={["edit", "delete"]}
                    onSubmitComment={onEdit}
                    loading={loading}
                    onDelete={onDelete}
                  />
                ))}
              </Grid>
            </Fragment>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

ProjectComments.propTypes = {
  mgrOptions: PropTypes.array.isRequired,
  monthOptions: PropTypes.array.isRequired,
  projectData: PropTypes.object.isRequired,
};

export default ProjectComments;
