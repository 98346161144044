import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import styled from "@emotion/styled";

const StyledAutocomplete = styled(Autocomplete,{
  shouldForwardProp: (prop) => {
    return prop !== "inputColor" ;
  }
})(({inputColor})=>({
  
  "&.Mui-focused .MuiInputLabel-outlined": {
    color: inputColor || 'inherit' 
  },
  "& .MuiAutocomplete-inputRoot": {
    color: inputColor || 'inheriit',    
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: inputColor || 'inherit'
    }
  }
}));

const SearchSelect = (props) => {
  const { value,label,options, optionDisplayVar,onChange,inputColor,clearable,fullWidth, sx, inputSx, disabled } = props;

  return (
    <StyledAutocomplete
      id="controlled-demo"
      // size="small"
      options={options}
      value={value}      
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      renderInput={(params) => (
        <TextField {...params} size="small" label={label} variant="filled" sx={inputSx} />
      )}
      disableClearable={!clearable}
      getOptionLabel={(option) => option[optionDisplayVar] || ""}
      isOptionEqualToValue={(option,value)=>option.id===value.id}
      fullWidth={fullWidth}
      inputColor={inputColor}
      sx={sx}
      disabled={disabled}
    />
  );
};

SearchSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  options: PropTypes.array,
  label:PropTypes.string,
  optionDisplayVar:PropTypes.string.isRequired,
  fullWidth:PropTypes.bool,
  onChange:PropTypes.func,
};

export default SearchSelect;
