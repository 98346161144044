import React, { useCallback, useEffect, useMemo, useState } from "react";
import ProjectsTable from "./ProjectsTable";
import "./index.css";
import { Divider, Grid, Typography, styled } from "@mui/material";
import SearchSelect from "../ComponentsLibrary/SearchSelect";
import axios from "axios";
import { url } from "../config";
import moment from "moment";
import { formatNumber, getWorkingAndCurrentDayInAMonth } from "../utils";
import IndexChart from "../UIComponents/DesignSystem/IndexChart";
import { Paper, Stack } from "../UIComponents";
import { getFilteredManagers } from "../Utils/misc";

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: "6px",
  padding: "16px",
}));

const commonSelectStyle = {
  minWidth: "150px",
};
const commontInputStyle = {
  ".MuiFilledInput-root": {
    // paddingTop: '9px',
    // paddingBottom: '9px !important'
  },
  ".MuiFilledInput-root:before": {
    borderBottom: "none",
  },
};

const ForcastPacingDashboard = (props) => {
  const monthNames = useMemo(
    () => [
      { id: 0, title: "January" },
      { id: 1, title: "February" },
      { id: 2, title: "March" },
      { id: 3, title: "April" },
      { id: 4, title: "May" },
      { id: 5, title: "June" },
      { id: 6, title: "July" },
      { id: 7, title: "August" },
      { id: 8, title: "September" },
      { id: 9, title: "October" },
      { id: 10, title: "November" },
      { id: 11, title: "December" },
    ],
    []
  );

  const [month, setMonth] = useState({});
  const [projects, setProjects] = useState([]);
  const [mgrsGrpdata, setMgrsGrpdata] = useState([]);
  const [loadingState, setLoading] = useState("loading");
  const [managers, setManagers] = useState([]);
  const [leads, setLeads] = useState([]);
  const [managerSelected, selectManager] = useState({
    id: "all",
    title: "All",
  });
  const [leadSelected, selectLead] = useState({ id: "all", title: "All" });

  const getStartAndEndDateByMonthName = useCallback((monthId) => {
    const currentYear = new Date().getFullYear();

    const firstDate = moment(`${currentYear}-${monthId + 1}-01`)
      .startOf("month")
      .format("YYYY-MM-DD");
    const lastDate = moment(`${currentYear}-${monthId + 1}-20`)
      .endOf("month")
      .format("YYYY-MM-DD");
    return {
      firstDate,
      lastDate,
    };
  }, []);

  useEffect(() => {
    let currentMonth = new Date().getMonth();
    setMonth({ ...monthNames[currentMonth] });
  }, [monthNames]);

  useEffect(() => {
    if (month.title) {
      const { firstDate, lastDate } = getStartAndEndDateByMonthName(month.id);
      setLoading("loading");
      axios
        .get(
          `${url}/forecast/dashboard?startDate=${firstDate}&endDate=${lastDate}&manager=${managerSelected.id}&lead=${leadSelected.id}`
        )
        .then((res) => {
          let data = groupDataByManagers(res.data);
          setMgrsGrpdata(data);
          setProjects(res.data);
          setLoading("success");
        })
        .catch((err) => {
          setLoading("failed");
          console.log(err);
        });
    }
  }, [
    month,
    managerSelected.id,
    leadSelected.id,
    getStartAndEndDateByMonthName,
  ]);

  // Get managers and leads
  useEffect(() => {
    axios
      .get(`${url}/profile/managers`)
      .then((res) => {
        const filteredManagers = res.data.map((manager) => manager.title);
        setManagers([
          { id: "all", title: "All" },
          ...getFilteredManagers({ managers: filteredManagers }).map(
            (manager) => ({ title: manager, key: manager })
          ),
        ]);
      })
      .catch((err) => console.log(err));

    axios
      .get(`${url}/profile/leads`)
      .then((res) => {
        setLeads([{ id: "all", title: "All" }, ...res.data]);
      })
      .catch((err) => console.log(err));
  }, []);

  // Group managers
  const groupDataByManagers = (data) => {
    const groupedArr = data.reduce((acc, curr) => {
      let managerName = curr.manager_name;
      if (!acc[managerName]) {
        // Initialise the group array
        acc[managerName] = { managerName, details: [] };
      }

      acc[managerName].details.push(curr);
      return acc;
    }, {});
    return Object.values(groupedArr);
  };

  const totalRevenue = useMemo(
    () =>
      projects.reduce((acc, curr) => {
        return acc + curr.project_cost;
      }, 0),
    [projects]
  );

  const totalHours = useMemo(
    () =>
      projects.reduce((acc, curr) => {
        return acc + curr.billable_minutes;
      }, 0),
    [projects]
  );

  const totalAllocHours = useMemo(
    () =>
      projects.reduce((acc, curr) => {
        return acc + curr.allocated_minutes;
      }, 0),
    [projects]
  );

  const totalAllocRevenue = useMemo(
    () =>
      projects.reduce((acc, curr) => {
        return acc + curr.allocated_minutes_revenue;
      }, 0),
    [projects]
  );

  const workingDays = useMemo(() => getWorkingAndCurrentDayInAMonth(), []);

  const hoursToBeOnSchedule = useMemo(
    () =>
      projects.reduce((acc, cur) => {
        let expWhrs =
          (cur.allocated_minutes / 60 / workingDays.totalWorkDays) *
          workingDays.currentDay;
        return formatNumber(acc + expWhrs);
      }, 0),
    [projects, workingDays]
  );

  const foreCastToDate = formatNumber(totalHours / 60) - hoursToBeOnSchedule;

  const forecastChartData = [
    {
      title: "Hours",
      value: parseFloat(Number(totalAllocHours / 60).toFixed(2)),
    },
    {
      title: "Revenue",
      value: totalAllocRevenue.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }),
    },
  ];
  const currentChartData = [
    {
      title: "Hours",
      value: parseFloat(Number(totalHours / 60).toFixed(2)),
    },
    {
      title: "Revenue",
      value: totalRevenue.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }),
    },
  ];

  const forecastToDateChartData = [
    {
      title: "Hours",
      value: formatNumber(totalHours / 60),
      color: foreCastToDate < 0 ? "#d32f2f" : "#09c609",
    },
    {
      title: "Hours Pacing",
      value: formatNumber(Math.abs(foreCastToDate)),
      color: foreCastToDate < 0 ? "#d32f2f" : "#09c609",
    },
    {
      title: "Completed to-date",
      value:
        formatNumber(
          (formatNumber(totalHours / 60) / hoursToBeOnSchedule) * 100
        ) + "%",
      color: foreCastToDate < 0 ? "#d32f2f" : "#09c609",
    },
  ];

  const pacingChartData = [
    {
      title: "Of month complete",
      value:
        parseFloat(
          Number((totalHours / totalAllocHours) * 100 || 0).toFixed(2)
        ) + "%",
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <StyledPaper direction={"column"} spacing={2} mt={2}>
          <Grid container gap={2} justifyContent="space-between">
            <Grid item xs={12}>
              <Stack
                direction="row"
                justifyContent="flex-start"
                gap={2}
                flexWrap="wrap"
              >
                <Stack direction="row" gap={2} flexWrap="wrap">
                  <IndexChart chartTitle="Forecast" data={forecastChartData} />
                  <IndexChart chartTitle="Current" data={currentChartData} />
                  <IndexChart chartTitle="Pacing" data={pacingChartData} />
                </Stack>
                <Stack direction="row" gap={2} alignItems="flex-end">
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                      height: "auto !important",
                      marginBottom: "-14px",
                      border: "1px solid #646FA7",
                      width: "2px",
                      backgroundColor: "#646FA7",
                    }}
                  />
                  <IndexChart
                    chartTitle="Forecast to-date"
                    data={forecastToDateChartData}
                  />
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Typography fontSize="26px">Report</Typography>
            </Grid>
            <Grid item xs={8}>
              <Stack justifyContent="flex-end" direction="row" gap={2}>
                <SearchSelect
                  id="Forcast-Month"
                  label="Month"
                  value={month}
                  options={monthNames}
                  onChange={(month) => setMonth(month)}
                  optionDisplayVar="title"
                  inputColor="#000"
                  sx={commonSelectStyle}
                  inputSx={commontInputStyle}
                />
                <SearchSelect
                  id="Forcast-Manager-select"
                  value={managerSelected}
                  options={managers}
                  onChange={(manager) => selectManager(manager)}
                  optionDisplayVar="title"
                  label="Manager"
                  inputColor="#000"
                  sx={commonSelectStyle}
                  inputSx={commontInputStyle}
                />
                <SearchSelect
                  id="Forcast-Lead-select"
                  value={leadSelected}
                  options={leads}
                  label="Lead"
                  onChange={(lead) => selectLead(lead)}
                  optionDisplayVar="title"
                  inputColor="#000"
                  sx={commonSelectStyle}
                  inputSx={commontInputStyle}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <ProjectsTable
                projects={mgrsGrpdata}
                loadingState={loadingState}
                managers={managers}
                monthOptions={monthNames}
                workingDays={workingDays}
              />
            </Grid>
          </Grid>
        </StyledPaper>
      </Grid>
    </Grid>
  );
};

export default ForcastPacingDashboard;
