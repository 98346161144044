import React, { Fragment } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../CustomHooks/AuthenticationHook";
import NavbarMain from "../components/Navbar";


export const AuthorisedRoute = ({ children }) => {
    const {user} =  useAuth(); 
    if (!user) {
      console.log('Not authenticated')
      // Redirect when user is not authenticated
      return <Navigate to="/" />;
    }

  return (
    <Fragment>
        <Fragment>
          <NavbarMain />         
        </Fragment>
    </Fragment>
  );
};
