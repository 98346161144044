import { styled } from '@mui/material';
import { Button as ButtonComp } from '../index.js'

const StyledButton = styled(ButtonComp)(({ theme }) => ({
}));

const Button = ({ ...rest }) => {
    return ( <StyledButton disableElevation variant="contained" {...rest}/> );
}
 
export default Button;