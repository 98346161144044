const { httpCall } = require("../httpCalls");

export const getWrokspacesAPI = async () => {
  const { data: reports } = await httpCall({
    type: "GET",
    url: "projects",
  });
  return reports;
};

export const getWorkspaceMeetings = async ({ workspaceId }) => {
  const { data: workspaceMeetings } = await httpCall({
    type: "GET",
    url: `projects/${workspaceId}/meetings`,
  });
  return workspaceMeetings;
};

export const getDeliverablesAPI = async ({ workspace_id }) => {
  const { data: workspaceDeliverables } = await httpCall({
    type: "GET",
    url: `deliverable/${workspace_id}`,
  });
  return workspaceDeliverables;
}

export const getWorkspaceResourcesAPI = async ({ workspace_id }) => {
  const response = await httpCall({
    type: "POST",
    url: 'mavenlink',
    data: {
      endpoint: `/workspace_resources?workspace_id=${workspace_id}`,
      type: 'GET',
    }
  });
  const { workspace_resources } = response.data;
  return workspace_resources;
}