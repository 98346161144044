import { Grid } from "@mui/material";
import NonBillableTable from "./NonBillableTable/index.js";
import { NonBillableFormPaper } from "./NonBillableTable/styledComponents.js";

const UpdateNonBillable = () => {
  return (
    <>
      <Grid
        container
        spacing={2}>
        <Grid
          item
          xs={12}
          xl={12}>
          <NonBillableFormPaper elevation={3}>
            <NonBillableTable />
          </NonBillableFormPaper>
        </Grid>
      </Grid>
    </>
  );
};

export default UpdateNonBillable;
