import { httpCall } from "../../httpCalls";

export const updateUsersApprovedNonBillable = async ({
  member_id,
  hours_approved,
  start_date,
  end_date,
  approved_hour_entries,
}) => {
  return await httpCall({
    type: "PUT",
    url: "non-billable",
    data: {
      member_id,
      hours_approved,
      start_date,
      end_date,
      approved_hour_entries,
    },
  });
};

export const getUsersApprovedNonBillableAPI = async ({
  userId,
  startDate,
  endDate,
}) => {
  return await httpCall({
    type: "GET",
    url: `non-billable/${userId}?start_date=${startDate}&end_date=${endDate}`,
  });
};

export const getUsersTimeDataAPI = async ({
  startDate,
  endDate,
  member_ids = "",
}) => {
  return await httpCall({
    type: "GET",
    url: `non-billable?from_date=${startDate}&to_date=${endDate}&member_ids=${member_ids}`,
  });
};

export const getUserBillableAPI = async ({ userId, startDate, endDate }) => {
  return await httpCall({
    type: "GET",
    url: `billable/${userId}?from_date=${startDate}&to_date=${endDate}`,
  });
};
