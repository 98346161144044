import React from "react";
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../CustomHooks/AuthenticationHook";
import { setAuthToken } from "../utils";
import { httpCall } from "../apis/httpCalls";

const Login = () => {
  const { setToken } = useAuth();
  const navigate = useNavigate();

  const handleGoogleAuthSuccess = async (googleResponse) => {
    try {
      const {
        data: { ip, country_name, city, postal, latitude, longitude, org } = {},
      } = await httpCall({
        type: "GET",
        url: `https://ipapi.co/json`,
        customURLPrefix: true,
        axiosProps: {
          withCredentials: false,
        },
      });
      const ips = `ip:${ip},country: ${country_name},city:${city},postal:${postal},lat_lon:Lat: "${latitude} | Lon: ${longitude},Provider: ${org}`;
      const payload = {
        googleJwt: googleResponse.credential,
        ips: ips,
      };

      const { data: loginResponse } = await httpCall({
        type: "POST",
        url: `login`,
        data: payload,
      });
      const { accessToken } = loginResponse;

      setToken(accessToken);
      setAuthToken(accessToken);
      toast("Authentication Successful!");
      navigate("/dashboard");
    } catch (error) {
      if (error.response) {
        toast.error(`Error occured at server. ${error.response}`);
      }
    }
  };

  return (
    <section className='hero has-background-black is-fullheight is-fullwidth'>
      <div className='hero-body'>
        <div className='container'>
          <div className='columns is-centered'>
            <div
              className='column is-4-desktop'
              style={{
                boxShadow: "1px -2px 20px 13px #00000075",
              }}>
              <div className='box'>
                <div
                  className='text-center'
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}>
                  <img
                    src='/logo-new.png'
                    alt='mini-logo'
                    style={{
                      width: "250px",
                      textAlign: "center",
                    }}
                  />
                  <GoogleLogin
                    onSuccess={handleGoogleAuthSuccess}
                    onError={() => {
                      toast.error("Authentication Failed from Google!");
                      console.log("Login Failed");
                    }}
                  />
                </div>
              </div>
              ;
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
