import * as React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";
import { CustomTabContainer, CustomTabPanel } from "./index";
import CustomBadge from "../../ComponentsLibrary/CustomBadge";
import moment from "moment";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ProgressBarWithText from "../../ComponentsLibrary/ProgressBarWithText";
import { formatNumber } from "../../utils";
import InformaticTitle from "../../ComponentsLibrary/InformaticTitle";

const CustomLabel = ({ label, color, textColor }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    }}
  >
    <FiberManualRecordIcon
      fontSize="small"
      sx={{ color: color || "inherit", pr: 1 / 2 }}
    />
    <Typography
      variant="Medium"
      sx={{ color: textColor || color || "inherit" }}
    >
      {label}
    </Typography>
  </div>
);

const HoursTabs = (props) => {
  const [value, setValue] = React.useState(0);
  const {
    timeLogged,
    scheduledHours,
    disableTimeLogged,
    enableAllocatedHours,
    allocatedHours,
    monthTab,
  } = props;
  let tabs = [
    {
      name: "Scheduled Hours",
      value: 0,
      titleinfo: `The hours scheduled for specific tasks on any day, for this ${
        monthTab ? "month" : "week"
      }, are based on task duration, responsibilities, and the nature of the work. The progress bar shows the time logged on the project in the current week. Calculation (${
        monthTab ? "Monthly" : "Weekly"
      }): ((Estimated Hours / Number of Assignees) / Task Duration (Days))`,
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const isTodayOrFutureDate = (dateString) => {
    const givenDate = moment(dateString, "YYYY-MM-DD");
    const todayDate = moment();

    return givenDate.isSameOrAfter(todayDate, "date");
  };
  if (enableAllocatedHours) {
    tabs = [...tabs, { name: "Allocated Hours", value: 1, titleinfo:'The hours hard-allocated for a specific project this month are based on the allocation created by the Managers. The progress bar indicates the time logged on that project in the current month.' }];
  }
  return (
    <CustomTabContainer
      label={"Hours Tabs"}
      value={value}
      handleChange={handleChange}
      tabs={tabs}
      titleInfoEnabled
    >
      <CustomTabPanel
        value={value}
        index={0}
        boxProps={{ sx: { p: 0, pt: 2, pb: 2 } }}
      >
        <Stack direction={"column"} spacing={1}>
          <Stack
            direction={"column"}
            spacing={1}
            sx={{ maxHeight: 260, overflowY: "scroll" }}
          >
            {scheduledHours?.map((client, i) => (
              <Stack
                key={`sch-hrs-${client.workspace_id}`}
                direction={"column"}
              >
                <Typography variant="Medium">
                  {client.workspace_title}
                </Typography>
                <ProgressBarWithText
                  value={formatNumber(client.total_billable_hours) + "h"}
                  maxValue={formatNumber(client.scheduled_hours) + "h"}
                  minValue={"0h"}
                  showScale
                  // steps={[
                  //   client.total_billable_hours,
                  //   client.total_non_billable_hours,
                  // ]}
                />
              </Stack>
            ))}
          </Stack>
          {!disableTimeLogged && (
            <React.Fragment>
              <InformaticTitle
                label="TIME LOGGED"
                titleinfo={
                  <Stack
                    direction={"column"}
                    spacing={1}
                  >
                    <CustomLabel
                      textColor={"#FFF"}
                      label={
                        "Indicates that time was logged on the same day the task was performed."
                      }
                      color={"#28C76F"}
                    />
                    <CustomLabel
                      textColor={"#FFF"}
                      label={
                        "Indicates that time was logged later than the day the task was performed."
                      }
                      color={"#FF9F43"}
                    />
                    <CustomLabel
                      textColor={"#FFF"}
                      label={
                        "Indicates that time is not yet logged and entry is still missing."
                      }
                      color={"#EA5455"}
                    />
                  </Stack>
                }
              />
              <Stack direction={"row"} flexWrap={"wrap"} spacing={1} useFlexGap>
                {timeLogged.map((entry, i) => {
                  let notWorkDay = 
                    entry.calculated_status?.toLowerCase() === "holiday" ||
                    entry.calculated_status?.toLowerCase() === "pto";
                  let isFutureDate = isTodayOrFutureDate(entry.date_performed);
                  let day = entry.day?.substring(0, 3) + ".";
                  return (
                    <CustomBadge
                      key={entry.date_performed}
                      text={day}
                      enableIcon={isFutureDate || notWorkDay ? false : true}
                      state={
                        entry.calculated_status === "On-Time"
                          ? "success"
                          : entry.calculated_status === "Missing"
                          ? "overdue"
                          : "due"
                      }
                      backgroundColor={
                        isFutureDate || notWorkDay ? "#F8F8F8" : null
                      }
                      squareBorder
                      color={isFutureDate || notWorkDay ? "#C6C6C6" : null}
                      strikeThrough={notWorkDay}
                    />
                  );
                })}
              </Stack>

              <Stack
                direction={"row"}
                flexWrap={"wrap"}
                spacing={2}
                useFlexGap
                alignItems={"center"}
                pt={1}
              >
                <CustomLabel label="On Time" color={"#28C76F"} />
                <CustomLabel label="Late" color={"#FF9F43"} />
                <CustomLabel label="Missing" color="#EA5455" />
              </Stack>
            </React.Fragment>
          )}
        </Stack>
      </CustomTabPanel>
      <CustomTabPanel
        value={value}
        index={1}
        boxProps={{ sx: { p: 0, pt: 2, pb: 2 } }}
      >
        <Stack direction={"column"} spacing={1}>
          <Stack
            direction={"column"}
            spacing={1}
            sx={{ maxHeight: 260, overflowY: "scroll" }}
          >
            {allocatedHours?.map((client, i) => (
              <Stack key={`sch-hrs-${client.id}`} direction={"column"}>
                <Typography variant="Medium">{client.title}</Typography>
                <ProgressBarWithText
                  value={formatNumber(client.billable_hours || 0) + "h"}
                  maxValue={formatNumber(client.allocated_hours) + "h"}
                  minValue={"0h"}
                  showScale
                />
              </Stack>
            ))}
            {allocatedHours.length === 0 && (
              <Typography variant="Medium">No Data Found</Typography>
            )}
          </Stack>
        </Stack>
      </CustomTabPanel>
    </CustomTabContainer>
  );
};

HoursTabs.propTypes = {
  timeLogged: PropTypes.array.isRequired,
  disableTimeLogged: PropTypes.bool,
  enableAllocatedHours: PropTypes.bool,
};

export { HoursTabs, CustomLabel };
