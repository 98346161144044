import React, { Fragment, useMemo } from "react";
import PropTypes from "prop-types";
import { TableCell, TableRow, Typography, styled } from "@mui/material";
import ProjectRow from "./ProjectRow";
import { formatCurrecy, formatNumber } from "../utils";

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
}));


const LeadRow = (props) => {
  const { manager, workingDays,openComments } = props;

  const groupLeadsByName = useMemo(
    () =>
      manager.details.reduce((res, item) => {
        let leadName = item.project_lead;
        if (!res[leadName]) {
          res[leadName] = { leadName, details: [] };
        }
        res[leadName].details.push(item);
        return res;
      }, {}),
    [manager]
  );


  const managerTotalNumbers = useMemo(() => {
    const manager_total = manager.details.reduce((acc, curr) => {
      const tmp = { ...acc };
      tmp.total_budget_remaining += curr.budget_remaining ? parseFloat(String(curr.budget_remaining).replace('$','').replaceAll(',', '')) : 0;
      tmp.total_allocated_minutes += curr.allocated_minutes;
      tmp.total_billable_minutes += curr.billable_minutes;
      tmp.total_nonbillable_minutes += curr.nonbillable_minutes;
      tmp.total_allocated_minutes_revenue += curr.allocated_minutes_revenue;
      tmp.total_current_revenue += curr.project_cost;
      tmp.total_forecast_pacing_hours += formatNumber(curr.billable_minutes - (curr.allocated_minutes / workingDays.totalWorkDays) * workingDays.currentDay);
      return tmp;
    },{ 
      total_budget_remaining: 0,
      total_allocated_minutes: 0,
      total_billable_minutes: 0,
      total_nonbillable_minutes: 0,
      total_project_delta: 0,
      total_allocated_minutes_revenue: 0,
      total_current_revenue: 0,
      total_revenue_pacing_delta: 0,
      total_forecast_pacing_hours: 0,
    });
    const new_manager_total = {
      total_budget_remaining: formatCurrecy(manager_total.total_budget_remaining),
      total_allocated_hours: manager_total.total_allocated_minutes / 60,
      total_billable_minutes: manager_total.total_billable_minutes / 60,
      total_nonbillable_minutes: manager_total.total_nonbillable_minutes / 60,
      total_hour_pacing_delta: formatNumber(manager_total.total_allocated_minutes - manager_total.total_billable_minutes, 1),
      total_allocated_minutes_revenue: formatCurrecy(manager_total.total_allocated_minutes_revenue),
      total_current_revenue: formatCurrecy(manager_total.total_current_revenue),
      total_revenue_pacing_delta: formatCurrecy(formatNumber(manager_total.total_allocated_minutes_revenue - manager_total.total_current_revenue)),
      total_forecast_pacing_hours: formatNumber((manager_total.total_billable_minutes / 60) - ((manager_total.total_allocated_minutes / 60 / workingDays.totalWorkDays) * workingDays.currentDay)),
    }


    return new_manager_total;


  }, [manager.details, workingDays])

  const groupedLeadsArr = useMemo(
    () => Object.values(groupLeadsByName),
    [groupLeadsByName]
  );

  return (
    <React.Fragment>
      <TableRow>
        <TableCell size="verysmall"  rowSpan={manager.details.length + groupedLeadsArr.length+ 1} className="td-b-r">
          {manager.managerName}
        </TableCell>
      </TableRow>
      {groupedLeadsArr.map((l, i) => (
        <Fragment>
          <TableRow key={`l-${i}-${manager.managerName}}-${l.leadName}`}>
            <TableCell size="verysmall" rowSpan={l.details.length + 1} className="td-b-r">
              {l.leadName}
            </TableCell>
          </TableRow>
          {l.details.map((project, ix) => (
            <ProjectRow key={`ses-${ix}-${project.project_id}`} project={project} workingDays={workingDays} openComments={openComments}/>
          ))}
        </Fragment>
      ))}
      <StyledTableRow>
        <TableCell size="verysmall" className="td-b-r">
          Total
        </TableCell>
        <TableCell size="verysmall" colSpan={3} className="td-b-r" />
        <TableCell size="verysmall" className="td-b-r" >
          {managerTotalNumbers.total_budget_remaining}
        </TableCell>
        <TableCell size="verysmall" className="td-b-r" >
          {managerTotalNumbers.total_allocated_hours}
        </TableCell>
        <TableCell size="verysmall" className="td-b-r" >
          {managerTotalNumbers.total_billable_minutes}
        </TableCell>
        <TableCell size="verysmall" className="td-b-r" >
          {managerTotalNumbers.total_nonbillable_minutes}
        </TableCell>
        <TableCell size="verysmall" className="td-b-r" >
          {managerTotalNumbers.total_hour_pacing_delta}
        </TableCell>
        <TableCell size="verysmall" className="td-b-r" >
          {managerTotalNumbers.total_allocated_minutes_revenue}
        </TableCell>
        <TableCell size="verysmall" className="td-b-r" >
          {managerTotalNumbers.total_current_revenue}
        </TableCell>
        <TableCell size="verysmall" className="td-b-r" >
          {managerTotalNumbers.total_revenue_pacing_delta}
        </TableCell>
        <TableCell size="verysmall" className="td-b-r" >
          <Typography
              variant="body2"
              color={managerTotalNumbers.total_forecast_pacing_hours < 0 ? "error" : "green"}
            >
          {managerTotalNumbers.total_forecast_pacing_hours}
          </Typography>
        </TableCell>
      </StyledTableRow>
    </React.Fragment>
  );
};

LeadRow.propTypes = {
    manager:PropTypes.object.isRequired,
    workingDays:PropTypes.object.isRequired
};

export default LeadRow;
