import { NewQuillEditor, Stack } from "../../UIComponents"

const MeetingAgenda = ({ agendaHTML, setAgendaHTML }) => {
    return ( 
        <Stack
            gap={1} 
            sx={{
                width: '400px',
            }}
        >
            <NewQuillEditor
                placeholder="Write your Agenda..."
                onChange={({ html }) => setAgendaHTML(html)}
                value={agendaHTML}
                editorHeight="150px"
            />
      </Stack>
     );
}
 
export default MeetingAgenda;