/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { Grid, Stack, Typography } from "../../../UIComponents";
import { createNewDeliverableNote, updateDeliverablesNoteThunk } from "../../../redux/reducers/decisions";
import DoneIcon from '@mui/icons-material/Done';
import { styled } from "@mui/material";
import { useContext, useMemo } from "react";
import { ValidationContext } from "../DecisionTrackerForm";
import { preventCustomPropForwarding } from "../../../Utils/MUI";
import IconButton from "../../../UIComponents/DesignSystem/IconButtton";

export const StyledIconButton = styled(IconButton, preventCustomPropForwarding(['active']))(({ theme, active }) => ({
  height: '24px',
  padding: 0,
  aspectRatio: '1 / 1', 
  color: active && `${theme.palette.secondary.main}`,
  '&> svg': {
    height: '1rem',
    width: '1rem',
  }
}));

const DeliverableItemHeader = ({ workspace_id, meeting_id, deliverable_id, selectedNoteValue, selectedNote, setSelectedNote  }) => {
  const dispatch = useDispatch();
  const { d_t_validation_setters: { remove_unsaved_deliverable } } = useContext(ValidationContext);

  
  const { isLoading: isNoteCreationLoading } = useSelector(state => state.apiStatus['decisions/createNewDeliverableNote']) || {};
  const { isLoading: isNoteUpdatationLoading } = useSelector(state => state.apiStatus['decision/updateDeliverablesNoteThunk']) || {};
  const isLoading = selectedNote?.note_id === 'dummy' ? isNoteCreationLoading : isNoteUpdatationLoading;

  const deliverable = useSelector(state => state.workspaces.deliverables[workspace_id]?.[deliverable_id]) || {};
  const { title, due_date, state } = deliverable;

  const isSelectedNoteValueChanged = useMemo(() => {
    if(!selectedNote) return false
    else if(selectedNote.meeting_id !== Number(meeting_id)) return false;
    else return selectedNote?.note_content !== selectedNoteValue
  }, [selectedNoteValue, selectedNote?.note_content]);

  // useEffect(() => {
  //   if(!selectedNote?.note_id) return;
  //   if(selectedNote.note_id === 'dummy' && selectedNoteValue) {
  //     add_unsaved_deliverable({ deliverableId: deliverable_id, meetingId: meeting_id, noteValue: selectedNoteValue });
  //     return
  //   } else if(isSelectedNoteValueChanged) {
  //     add_unsaved_deliverable({ noteId: selectedNote.note_id, meetingId: meeting_id, noteValue: selectedNoteValue });
  //     return;
  //   }
  //   // if(d_t_validation.unsaved_deliverable_notes_ids.includes(selectedNote?.note_id)) 
  //   //   remove_unsaved_deliverable({ noteId: selectedNote.note_id, meetingId: meeting_id });

  // }, [isSelectedNoteValueChanged, selectedNote?.note_id, selectedNoteValue])

  const handleDeliverableNoteSave = async (e) => {
    e.stopPropagation(); /** Stopping propogation so btn does not toggle Accordian */
    if(selectedNote?.note_id === 'dummy'){
      const { payload: updatedSelectedNote } = await dispatch(createNewDeliverableNote({ workspace_id, deliverable_id, meeting_id, note_content: selectedNoteValue }));
      remove_unsaved_deliverable({ deliverableId: deliverable_id, meetingId: meeting_id });
      if(updatedSelectedNote) {
        setSelectedNote(updatedSelectedNote)
      }
    }
    else {
      const { payload: updatedSelectedNote } = await dispatch(updateDeliverablesNoteThunk({ note_id: selectedNote.note_id, note_content: selectedNoteValue }));
      console.log({ message: 'handleDeliverableNoteSave is called' });
      remove_unsaved_deliverable({ noteId: selectedNote.note_id, meetingId: meeting_id });
      if(updatedSelectedNote) {
        setSelectedNote(updatedSelectedNote)
      }
    }
  }


    return ( 
        <Grid container
        alignItems="center"
        >
          <Grid
            item
            xs={3}>
            <Typography textAlign="left">{title}</Typography>
          </Grid>
          <Grid
            item
            xs={3}>
            <Stack
              direction='row'
              gap={1}>
              <Typography fontWeight={700}>Due Date: </Typography>
              <Typography>{due_date}</Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={3}>
            <Stack
              direction='row'
              gap={1}>
              <Typography fontWeight={700}>Status: </Typography>
              <Typography>{state}</Typography>
            </Stack>
          </Grid>
          <Grid
              item
              sm={2.8}>
              <Stack
                gap={2}
                direction='row'
                alignItems='center'
                sx={{ height: "100%" }}
                justifyContent='flex-end'>
                <StyledIconButton 
                  color='primary'
                  variant='outlined'
                  size="small"                  
                  disabled={isLoading || !isSelectedNoteValueChanged}
                  onClick={handleDeliverableNoteSave}               
                >
                  <DoneIcon />
                </StyledIconButton>
              </Stack>
            </Grid>
        </Grid>
     );
}
 
export default DeliverableItemHeader;