import { httpCall } from "../httpCalls";

export const getAllUsersAPI = async () => {
  const { data: usersArr } = await httpCall({
    type: "GET",
    url: "users",
  });
  return usersArr;
};

export const getAllManagersAPI = async () => {
  const { data: managersArr } = await httpCall({
    type: "GET",
    url: "profile/managers",
  });
  return managersArr;
};

export const getAllLeadsAPI = async () => {
  const { data: managersArr } = await httpCall({
    type: "GET",
    url: "profile/leads",
  });
  return managersArr;
};

