/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography, Stack, NewQuillEditor } from "../../../UIComponents";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import { toast } from "react-toastify";
import { resetStatusData } from "../../../redux/reducers/apiStatus";
import DeliverableNoteHeader from "./DeliverableNoteHeader";
import { styled } from "@mui/material";
import { StyledIconButton } from "./DeliverableItemHeader";
import { ValidationContext } from "../DecisionTrackerForm";
import { useContext } from "react";

const StyledNoteListGridItem = styled(Grid)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: "8px",
  boxSizing: "border-box",
  padding: "0.5rem",
  minHeight: '269px',
}));

const StyledNoteListContainerStack = styled(Stack)(({ theme }) => ({
  width: "100%",
  overflowY: 'auto',
  borderTop: `1px solid ${theme.palette.border.main}`,
  marginTop: '4px',
  paddingTop: '8px'
}));

const getDummyDeliverableNote = ({ note_id = 'dummy', deliverable_id, meeting_id, workspace_id, note_content = "" }) => ({
    note_id,
    deliverable_id,
    meeting_id,
    workspace_id,
    note_content
});



let DeliverablesItemNotes = ({ meeting_id, workspace_id, deliverable_id, selectedNote, selectedNoteValue, setSelectedNote, setSelectedNoteValue }) => {
    const dispatch = useDispatch(); 
    const { d_t_validation_setters: { remove_unsaved_deliverable, add_unsaved_deliverable }, d_t_validation } = useContext(ValidationContext);

  const { mentionList } = useSelector(state => state.workspaces.resources[workspace_id]) || {};

    const { isSuccess: isNoteCreationSuccess } = useSelector(state => state.apiStatus['decisions/createNewDeliverableNote']) || {};
    const { isSuccess: isNoteUpdateSuccess } = useSelector(state => state.apiStatus['decision/updateDeliverablesNoteThunk']) || {};
    
    const notes_map = useSelector(state => state.decisions.deliverables_notes[workspace_id]?.[deliverable_id]) || {};
    const [currentMeetingNoteId, setCurrentMeetingNoteId] = useState();
    
    const isSuccess =  selectedNote?.note_id === 'dummy' ? isNoteCreationSuccess : isNoteUpdateSuccess;

    useEffect(() => {
      if(isSuccess) {
        if(selectedNote?.note_id === 'dummy') dispatch(resetStatusData({ apiIdentifier: 'decisions/createNewDeliverableNote' }));
        else dispatch(resetStatusData({ apiIdentifier: 'decision/updateDeliverablesNoteThunk' }));
      }
    }, [isSuccess, dispatch, selectedNote?.note_id]);

    useEffect(() => {
      return () => {
        if(
          d_t_validation.unsaved_deliverable_notes_ids.includes(`${deliverable_id}-dummy` 
          && d_t_validation.unsaved_deliverable_notes_entities[`${deliverable_id}-dummy`]) 
          && !d_t_validation.unsaved_deliverable_notes_entities[`${deliverable_id}-dummy`].value 
        ) remove_unsaved_deliverable({ deliverableId: deliverable_id, meetingId: meeting_id });
        
      }
    }, [d_t_validation]);

    const handleCreateNewNote = () => {
        let current_meeting_note;
        if(!currentMeetingNoteId) {
          Object.values(notes_map).forEach((note) => {
            if(note.meeting_id === Number(meeting_id)) {
              current_meeting_note = note;
            }
          });
        } else current_meeting_note = notes_map[currentMeetingNoteId];
        if(current_meeting_note) {
          toast.info('Note already exists');
          setCurrentMeetingNoteId(current_meeting_note.note_id);
          handleSelectedNoteChange({ deliverable_note: current_meeting_note});
        }
        else handleSelectedNoteChange({ deliverable_note: getDummyDeliverableNote({ deliverable_id, meeting_id, workspace_id })});
    }

   
    const handleSelectedNoteChange = ({ deliverable_note }) => {
      setSelectedNote(deliverable_note)
      setSelectedNoteValue(deliverable_note.note_content);
    }

    const handleNoteContentChange = (newContent) => {
      if(selectedNote?.note_id === 'dummy') add_unsaved_deliverable({ deliverableId: deliverable_id, meetingId: meeting_id, noteValue: newContent });
      else if(selectedNote?.meeting_id === Number(meeting_id)) add_unsaved_deliverable({ noteId: selectedNote.note_id, meetingId: meeting_id, noteValue: newContent });        
      setSelectedNoteValue(newContent);
    }

    return (
        <Stack>
          <Grid
            container
            gap={2}>
            <StyledNoteListGridItem
              item
              sm={4.5}
            >
              <Stack>
                    <Stack direction="row" alignItems="center" justifyContent="space-between"
                     sx={{
                        width: '100%'
                     }}
                    >
                        <Typography>Previous Deliverable Notes</Typography>
                        <StyledIconButton aria-label="create-note"
                            color="secondary"
                            onClick={handleCreateNewNote}
                        > 
                            <AddIcon />
                        </StyledIconButton>
                    </Stack>
                <StyledNoteListContainerStack>
                    {
                        Object.keys(notes_map).length === 0 
                        ?
                            <Typography>
                                No notes yet.
                            </Typography>
                        :            
                            Object.keys(notes_map)
                              .sort((note_id_curr, note_id_nxt) => note_id_nxt -  note_id_curr )
                              .map((note_id) => (                                
                                  <DeliverableNoteHeader
                                    key={note_id}
                                    selectedNoteId={selectedNote?.note_id}
                                    note_id={note_id}
                                    meeting_id={meeting_id}
                                    handleSelectedNoteChange={handleSelectedNoteChange} 
                                    workspace_id={workspace_id} 
                                    deliverable_id={deliverable_id}
                                  />
                            ))
                    }
                </StyledNoteListContainerStack>
              </Stack>
            </StyledNoteListGridItem>
            <Grid
              item
              sm={7}>
              {selectedNote?.note_id && (
                <NewQuillEditor
                    mentionList={mentionList}
                    value={selectedNoteValue} 
                    onChange={({ html }) => handleNoteContentChange(html)}
                    editorHeight="200px"
                    readOnly={selectedNote.note_id !== 'dummy' && selectedNote.meeting_id !== Number(meeting_id)}
                />
              )}
            </Grid>
          </Grid>
        </Stack>
      );
}
DeliverablesItemNotes = memo(DeliverablesItemNotes);
 
export default DeliverablesItemNotes;