import { Card, styled } from "@mui/material";
import { Stack, Typography } from "..";
import { preventCustomPropForwarding } from "../../Utils/MUI";
import Divider from '@mui/material/Divider';


const StyledCard = styled(Card, preventCustomPropForwarding([]))(({ theme}) => ({
    width: 'fit-content',
    padding: '12px 16px',
    borderRadius: '8px',
    borderColor: theme.palette.border.main,
}));

const IndexChart = ({ chartTitle="", data }) => {
    const charData = data;
    return ( 
    <Stack gap={1}>
        { chartTitle &&
            <Typography color="secondary" fontSize="12px">
                {chartTitle}
            </Typography>
        }
        <StyledCard elevation={5}>
            <Stack direction="row" gap={1}>
                {
                    charData.map(({ title, value, color }, index) => (
                        <>
                            {
                                index !== 0
                                && 
                                <Divider
                                orientation='vertical'
                                flexItem
                                sx={{
                                    height: "auto !important",
                                    border: "1px solid #646FA740",
                                }}
                            />
                            }
                            <Stack mr={{
                                xs: 3,
                                xl: 6,
                                }}>
                                <Typography color={color} fontWeight={700} fontSize='14px'>
                                    {value}
                                </Typography>
                                <Typography color="secondary" fontSize='8px'>
                                    {title}
                                </Typography>
                            </Stack>
                        </>
                    ))
                }
            </Stack>
        </StyledCard>
    </Stack>
     );
}
 
export default IndexChart;