import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { fetchCertificationDataAPI } from "../../apis/certificates";

const certificationsAdapter = createEntityAdapter({
  selectId: (certification) => certification.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const initialState = certificationsAdapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchCertificates = createAsyncThunk(
  "certifications/fetchCertificates",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchCertificationDataAPI();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const certificationsSlice = createSlice({
  name: "certifications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCertificates.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchCertificates.fulfilled, (state, action) => {
        state.status = "succeeded";
        certificationsAdapter.setAll(state, action.payload);
      })
      .addCase(fetchCertificates.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to load certifications";
      });
  },
});

export default certificationsSlice.reducer;

export const {
  selectAll: selectAllCertificates,
  selectById: selectCertificatesById,
} = certificationsAdapter.getSelectors((state) => state.certificates);
