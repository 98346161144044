import React, { Fragment, useMemo, useState, useEffect } from "react";
import axios from 'axios';
import PropTypes from "prop-types";
import { Divider, Grid, Stack, Typography } from "@mui/material";
import CustomBadge from "../../ComponentsLibrary/CustomBadge";
import InformaticTitle from "../../ComponentsLibrary/InformaticTitle";
import SkillsPopup2 from "./SkillsPopup2";
import AddIcon from '@mui/icons-material/Add';
import IconButton from "../../UIComponents/DesignSystem/IconButtton";

const MemberSkills = (props) => {
  const { skills } = props;
  const [platform, setSelectedPlatform] = useState(null);
  const [showSkillModal, setShowSkillModal] = useState(false)
  const [platformData, setPlatformData] = useState([])

  // Group managers
  const groupSkillsByPlatform = (data) => {
    const groupedArr = data.reduce((acc, curr) => {
      let platform_id = curr.platform_id;
      let platform_name = curr.platform_name;
      if (!acc[platform_id]) {
        // Initialise the group array
        acc[platform_id] = { platform_id, platform_name, skills: [] };
      }

      acc[platform_id].skills.push(curr);
      return acc;
    }, {});
    return Object.values(groupedArr);
  };

  const groupedSkills = useMemo(() => {
    if (skills?.length > 0) {
      return groupSkillsByPlatform(skills);
    }
    return [];
  }, [skills]);

  const filteredSkills = useMemo(() => {
    // console.log("Updated grouped skills are", groupedSkills)
    if (platform) {
      const filtSkills = groupedSkills.filter(
        (pltf, id) => platform === pltf.platform_id
      );
      return filtSkills[0].skills;
    } else {
      return [];
    }
  }, [groupedSkills, platform]);

  const onSelect = (pltf_id, color_id) => {
    if (pltf_id === platform) {
      setSelectedPlatform(null);
    } else {
      setSelectedPlatform(pltf_id);
    }
  };

  const fetchPlatformsWithSkills = async () => {
    axios
      .get(`${`${process.env.REACT_APP_PROD}/api/v1`}/platforms`)
      .then((res) => {        
        setPlatformData(res.data)
      }).catch((err) => {
        console.log("Something failed when fetching platforms", err)
      }).finally(() => {
        console.log("request finished")
        // setLoading(false)
      });
  }

  useEffect(() => {
    fetchPlatformsWithSkills();
  }, [])

  // console.log("platform data",platformData)
  return (
    <>
      {platformData.length > 0 && <SkillsPopup2 platformData={platformData} handleClose={() => { setShowSkillModal(false) }} isOpen={showSkillModal} userSkills={skills} {...props} />}
      <Grid container direction={"column"} p={1}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
          <InformaticTitle
            titleinfo={
              "Set of skills and expertise. This showcases the skills and sub-skills aquired within each Salesforce platform."
            }
            label="Skills"
          />
          {window.location.pathname.includes('member-profile') && props.isAddSkillPermitted && (
            <IconButton onClick={() => { setShowSkillModal(true) }}>
              <AddIcon 
                style={{                  
                  textTransform: "capitalize",
                  fontSize:"30px"
                }}
              />
            </IconButton>
          )}
          {/* <div><button onClick={() => { setShowSkillModal(true) }}>Add Skills</button></div> */}
        </div>


        <Stack
          direction={"row"}
          spacing={{ xs: 1, sm: 2 }}
          alignItems={"center"}
          flexWrap={"wrap"}
          useFlexGap
        >
          {groupedSkills.map((pltf, i) => (
            <CustomBadge
              key={`platform-${pltf.platform_id}`}
              text={pltf.platform_name}
              onClick={() => onSelect(pltf.platform_id, i)}
              backgroundColor={platform === pltf.platform_id ? '#00CFE8' : null}
              color="#FFF"
            />
          ))}
        </Stack>

        {filteredSkills.length > 0 && (
          <Fragment>
            <Divider
              sx={{ backgroundColor: "#0000001f", width: "100%", mt: 1 }}
            />
            <Stack
              direction={"row"}
              mt={1}
              spacing={{ xs: 1, sm: 2 }}
              alignItems={"center"}
              flexWrap={"wrap"}
              useFlexGap
            >
              {filteredSkills.map((skill, id) => (
                <CustomBadge
                  key={`skill-${skill.skill_id}`}
                  text={skill.skill_name}
                  backgroundColor={'#00CFE8'}
                  color="#FFF"
                />
              ))}
            </Stack>
          </Fragment>
        )}

        {groupedSkills.length === 0 && (
          <Typography variant="Medium" gutterBottom>
            NA
          </Typography>
        )}
      </Grid>
    </>

  );
};

MemberSkills.propTypes = {
  skills: PropTypes.array.isRequired,
};

export default MemberSkills;
