
import TableRow from "../../UIComponents/DesignSystem/TableRow";
import TableCell from "../../UIComponents/DesignSystem/TableCell";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMeetingsByWorkspace } from "../../redux/reducers/decisions";
import { Button, Typography } from "../../UIComponents";
import dayjs from "dayjs";

const WorkspaceRow = ({ workspace }) => {
    const  { 
        id,
        title, 
        status_message,
        due_date, 
        percentage_complete, 
        budgeted_hours, 
        used_budgeted_hours, 
        this_month_budgeted_hours, 
        this_week_budgeted_hours, 
        percent_of_budget_used  
    } = workspace;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleViewMeetingNotes = ({ workspace_id }) => {
        dispatch(getMeetingsByWorkspace({ workspace_id }));
        navigate(`/decision-tracker/${workspace_id}`);
      };

    return ( 
        <TableRow>
            <TableCell size="verysmall">
                <Typography
                variant="caption"
                color='primary'
                component={"a"}
                href={`https://magna.mavenlink.com/workspaces/${id}`}
                target="_blank"
                >
                    {title}
                </Typography>
            </TableCell>
            <TableCell size="verysmall">{status_message}</TableCell>
            <TableCell size="verysmall">{due_date ? dayjs(due_date).format('DD/MM/YYYY') : '-'}</TableCell>
            <TableCell size="verysmall">
                {percentage_complete || '-'}
            </TableCell>
            <TableCell size="verysmall">{budgeted_hours || '-'}</TableCell>
            <TableCell size="verysmall">{used_budgeted_hours || '-'}</TableCell>
            <TableCell size="verysmall">{this_month_budgeted_hours || '-'}</TableCell>
            <TableCell size="verysmall">
                {this_week_budgeted_hours || '-'}
            </TableCell>
            <TableCell size="verysmall">
                {percent_of_budget_used || '-'}
            </TableCell>
            <TableCell>
                <Button
                    onClick={() => handleViewMeetingNotes({ workspace_id: id })}
                    size='small'
                    color='primary'>
                    View Notes
                </Button>
            </TableCell>
        </TableRow>
     );
}
 
export default WorkspaceRow;