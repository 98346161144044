import React from "react";
import PropTypes from "prop-types";
import { Grid, Stack, Typography } from "@mui/material";
import CustomBadge from "../../ComponentsLibrary/CustomBadge";
import { formatDate } from "../../utils";
import InformaticTitle from "../../ComponentsLibrary/InformaticTitle";

const MemberCertifications = (props) => {
  const { certifications } = props;

  return (
    <Grid container direction={"column"} p={1}>
      <InformaticTitle
        label="Certifications"
        titleinfo={`A list of certifications you've achieved and those you're currently pursuing. Certifications highlighted in Green indicate those you currently hold. Those in Red have passed their due date, while those in Yellow are in progress and due soon.`}
      />
      <Stack
        direction={"row"}
        spacing={{ xs: 1, sm: 1 }}
        flexWrap={"wrap"}
        useFlexGap
        alignItems={"flex-start"}
      >
        {certifications.map((certi, i) => {
          let status = certi.certification_status;
          let state, date;
          if (status.toLowerCase() === "passed") {
            state = "success";
            date = certi.certification_acquired;
          } else if (status.toLowerCase() === "failed") {
            state = "overdue";
            date = certi.deadline;
          } else {
            state = "due";
            date = certi.deadline;
          }
          return (
            <CustomBadge
              key={`cert-${certi.certification_id}`}
              text={certi.certification_name}
              state={state}
              helperText={`${
                state === "success" ? "Completed" : "Due"
              } on ${formatDate(date)}`}
            />
          );
        })}
      </Stack>
      {certifications.length === 0 && (
        <Typography variant="Medium" gutterBottom>
          NA
        </Typography>
      )}
    </Grid>
  );
};

MemberCertifications.propTypes = {
  certifications: PropTypes.array.isRequired,
};

export default MemberCertifications;
