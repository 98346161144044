import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import {
  getCalculatedProjectStatusAPI,
  getProjectHealthReportsAPI,
  submitProjectHealthReportAPI,
} from "../../apis/Dashboards/index.js";
import dayjs from "dayjs";

const sortComparer = (arr, key, order = "desc") => {
  return arr.sort((a, b) => {
    const dateA = dayjs(a[key]);
    const dateB = dayjs(b[key]);

    if (!a[key]) return 1; // If `a` has no key (e.g., `submitted_on`), place it last
    if (!b[key]) return -1; // If `b` has no key, place it last

    if (dateA.isBefore(dateB)) {
      return order === "asc" ? -1 : 1; // Ascending: older first; Descending: newer first
    } else if (dateA.isAfter(dateB)) {
      return order === "asc" ? 1 : -1; // Ascending: newer first; Descending: older first
    } else {
      return 0; // Dates are equal
    }
  });
};

const calculatedAdapter = createEntityAdapter({
  selectId: (status) => status.project_id,
  sortComparer: (a, b) => {
    // Handle null manager_name (sort projects with null manager_name to the top)
    if (a.manager_name === null && b.manager_name !== null) return -1;
    if (a.manager_name !== null && b.manager_name === null) return 1;

    // First, compare by Manager Name
    if (a.manager_name < b.manager_name) return -1;
    if (a.manager_name > b.manager_name) return 1;

    // If manager names are the same, compare by Team Lead
    if (a.project_lead < b.project_lead) return -1;
    if (a.project_lead > b.project_lead) return 1;

    // If both manager name and team lead are the same, compare by project close date
    if (!a.project_close_date) return 1;
    if (!b.project_close_date) return -1;
    const dateA = dayjs(a.project_close_date);
    const dateB = dayjs(b.project_close_date);

    if (dateA.isBefore(dateB)) {
      return 1;
    } else if (dateA.isAfter(dateB)) {
      return -1;
    } else {
      return 0;
    }
  },
});

const historicalAdapter = createEntityAdapter({
  selectId: (report) => report.id,
  sortComparer: (a, b) => {
    if (!a.submitted_on) return 1;
    if (!b.submitted_on) return -1;

    const dateA = dayjs(a.submitted_on);
    const dateB = dayjs(b.submitted_on);

    if (dateA.isBefore(dateB)) {
      return 1;
    } else if (dateA.isAfter(dateB)) {
      return -1;
    } else {
      return 0;
    }
  },
});

const initialState = {
  calculated: calculatedAdapter.getInitialState({
    status: "idle",
    error: null,
  }),
  historical: historicalAdapter.getInitialState({
    status: "idle",
    error: null,
  }),
};

export const fetchCalculatedProjectStatus = createAsyncThunk(
  "projectHealthDashboard/fetchCalculatedProjectStatus",
  async ({ startDate, endDate, managers, leads, isArchieved }) => {
    const calculatedProjectStatus = await getCalculatedProjectStatusAPI({
      startDate,
      endDate,
      managers,
      leads,
      isArchieved,
    });
    return calculatedProjectStatus;
  }
);

export const fetchHistoricalReports = createAsyncThunk(
  "projectHealthDashboard/fetchHistoricalReports",
  async ({ project_id: projectId }, { rejectWithValue }) => {
    try {
      const response = await getProjectHealthReportsAPI({ projectId });
      return { projectId, healthReports: response };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const submitProjectHealthReport = createAsyncThunk(
  "projectHealthDashboard/submitProjectHealthReport",
  async (newReport, { rejectWithValue }) => {
    try {
      const response = await submitProjectHealthReportAPI({
        report: newReport,
      });
      return {
        id: response.id,
        workspace_id: response.workspace_id,
        overall: response.overall,
        schedule_date: response.schedule_date,
        remaining_budget: response.remaining_budget,
        remain_hours: response.remain_hours,
        rate: response.rate,
        client_engagement: response.client_engagement,
        client_timeliness: response.client_timeliness,
        for_week_of: response.for_week_of,
        submitted_on: response.submitted_on,
        submitted_by: response.submitted_by,
        snapshot: response.snapshot,
        budget: response.budget,
        schedule: response.schedule,
      };
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

const projectHealthDashboardSlice = createSlice({
  name: "projectHealthDashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle calculated project status
      .addCase(fetchCalculatedProjectStatus.pending, (state) => {
        state.calculated.status = "loading";
      })
      .addCase(fetchCalculatedProjectStatus.fulfilled, (state, action) => {
        state.calculated.status = "succeeded";
        calculatedAdapter.setAll(state.calculated, action.payload);
      })
      .addCase(fetchCalculatedProjectStatus.rejected, (state, action) => {
        state.calculated.status = "failed";
        state.calculated.error = action.error.message;
      })

      .addCase(fetchHistoricalReports.pending, (state) => {
        state.historical.status = "loading";
      })
      .addCase(fetchHistoricalReports.fulfilled, (state, action) => {
        const { projectId, healthReports } = action.payload;
        state.historical.status = "succeeded";
        const sortedHealthReports = sortComparer(
          healthReports,
          "submitted_on",
          "desc"
        );

        historicalAdapter.upsertMany(state.historical, sortedHealthReports);

        const historicalIds = sortedHealthReports.map((report) => report.id);

        const calculatedProject = state.calculated.entities[projectId];
        if (calculatedProject) {
          calculatedProject.historicalReportIds = historicalIds;
        }
      })
      .addCase(fetchHistoricalReports.rejected, (state, action) => {
        state.historical.status = "failed";
        state.historical.error = action.error.message;
      })

      // Handle new project health report submission
      .addCase(submitProjectHealthReport.pending, (state) => {
        state.historical.status = "loading";
      })
      .addCase(submitProjectHealthReport.fulfilled, (state, action) => {
        const projectId = action.payload.workspace_id;
        state.historical.status = "succeeded";

        historicalAdapter.addOne(state.historical, {
          id: projectId,
          ...action.payload,
        });

        // Optionally, update the calculated state if needed
        const calculatedProject = state.calculated.entities[projectId];
        if (calculatedProject) {
          calculatedProject.days_since_last_report = 0;
          calculatedProject.historicalReportIds = [
            ...(calculatedProject.historicalReportIds || []),
            action.payload.id,
          ];
        }
      })
      .addCase(submitProjectHealthReport.rejected, (state, action) => {
        state.historical.status = "failed";
        state.historical.error = action.error.message;
      });
  },
});

export default projectHealthDashboardSlice.reducer;

// Export selectors for calculated and historical entities
export const {
  selectAll: selectAllCalculatedProjectStatus,
  selectById: selectCalculatedProjectStatusById,
} = calculatedAdapter.getSelectors(
  (state) => state.projectHealthDashboard.calculated
);

export const {
  selectAll: selectAllHistoricalProjectHealthReports,
  selectById: selectHistoricalProjectHealthReportById,
} = historicalAdapter.getSelectors(
  (state) => state.projectHealthDashboard.historical
);
