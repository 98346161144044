import { Modal, Stack, Typography } from "../../UIComponents";
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import Button from "../../UIComponents/DesignSystem/Button";

const { Body } = Modal;

const ConfirmationModal = ({ isOpen, description, OkText, cancleText, onCancle, onOk, noCancleButton }) => {
    return ( <Modal show={isOpen} onHide={onOk}  centered >
        <Body>
            <Stack gap={2}>
                <Typography align="center" fontWeight={900}>
                    {description}
                </Typography>
                <Stack direction="row" gap={2} alignItems="center" justifyContent="center">
                    <Button variant="contained" color="secondaryLight" startIcon={<CheckIcon />} onClick={onOk}>
                        <Typography>
                            {OkText}
                        </Typography>
                    </Button>
                    {
                        !noCancleButton && 
                        <Button variant="contained" color="primaryLight" startIcon={<CancelIcon />} onClick={onCancle}>   
                            <Typography>
                                {cancleText}
                            </Typography>
                        </Button>
                    }
                </Stack>

            </Stack>
        </Body>
    </Modal> );
}

ConfirmationModal.defaultProps = {
    isOpen: false,
    title: 'Unsaved Changes', 
    description: '', 
    OkText: 'OK', 
    cancleText: 'Cancle',
    onCancle: () => {}, 
    onOk: () => {},
}

export default ConfirmationModal;