import { Typography, styled, useTheme } from "@mui/material";
import { Stack } from "../UIComponents";
import { PieChart } from "../UIComponents/Charts";

const StyledText = styled('text')(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: '14px',
  }));

function PieCenterLabel({ children, height }) {
    const width = height;
    return (
      <StyledText x={width / 2} y={height / 2}>
        {children}
      </StyledText>
    );
  }


const CircularProgress = ({ centerText = '', label, chartData = [], height = '100px', barWidth = 10 }) => {

  const theme = useTheme();
    const alignmentValue = (parseFloat(height) / 2) - 5;
    const isEmptyChart = parseFloat(centerText) <= 0;
    let emptyChart;
    if(isEmptyChart) emptyChart = [{ id: 0, value: 100, title: 'Hours Used', color: theme.palette.disabled.main }];
    return ( 
      <Stack sx={{
        width: height
      }}>
        <Stack sx={{
            height,
            width: '100%',            
        }}>
            <PieChart
                series={[
                    {
                        data: emptyChart || chartData,      
                        valueFormatter: ({ title, value: option_value }) => {
                            if(isEmptyChart) return `${title}: ${0} hrs`
                            return `${title}: ${option_value} hrs`
                        },                  
                        innerRadius: alignmentValue - barWidth,
                        outerRadius: alignmentValue,
                        paddingAngle: 1,
                        cornerRadius: 0,
                        startAngle: -130,
                        endAngle: 130,
                        cx: alignmentValue,
                        cy: alignmentValue,
                    }
                ]}
            >
                <PieCenterLabel height={parseFloat(height)}>{centerText}</PieCenterLabel>
            </PieChart>
        </Stack>
        <Typography align="center" fontWeight="bold" sx={{
          marginTop: '-20px'
        }}>{label}</Typography>
        </Stack>
    );
}
 
export default CircularProgress;