import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { features, getTargetMembersAPI, getUserTeamMembersAPI } from "../../apis/User/TeamMembers";
import {
  getUserBillableAPI,
  getUsersApprovedNonBillableAPI,
  getUsersTimeDataAPI,
  updateUsersApprovedNonBillable,
} from "../../apis/User/NonBillables";
import dayjs from "dayjs";
import { getAllUsersAPI } from "../../apis/User";
import { filterUsers } from "../../components/UpdateNonBillable/NonBillableTable/index.js";

export const getAllUsers = createAsyncThunk(
  "users/getAllUsers",
  async (_, { dispatch }) => {
    const usersMap = await getAllUsersAPI();
    dispatch(updateAllUsers({ usersMap }));
  }
);

export const getTargetMembers = createAsyncThunk("users/getTargetMembers", async ({ userId, featureId }, { dispatch }) => {
  const { data: targetMemberIds } = await getTargetMembersAPI({ userId, featureId });
  dispatch(updateTargetMembers({ featureId, targetMemberIds }))
  return targetMemberIds;    
});

export const getUserTeamMembers = createAsyncThunk(
  "users/getUserTeamMembers",
  async ({ userId }, { dispatch }) => {
    const response = await getUserTeamMembersAPI({ userId });
    dispatch(updateTeamMembers(response.data));
  }
);

export const getUserBillable = createAsyncThunk(
  "users/getUserBillable",
  async ({ userId, startDate, endDate }, { dispatch }) => {
    const response = await getUserBillableAPI({ userId, startDate, endDate });
    dispatch(updateUserBillable({ billable: response.data.data, userId }));
  }
);

export const updateUserApprovedNonBillable = createAsyncThunk(
  "users/updateUserApprovedNonBillable",
  async (
    { member_id, hours_approved, start_date, end_date, approved_hour_entries },
    { dispatch }
  ) => {
    await updateUsersApprovedNonBillable({
      member_id,
      hours_approved,
      start_date,
      end_date,
      approved_hour_entries,
    });
    dispatch(getUsersTimeData({startDate: start_date , endDate: end_date , givenUserIds: [member_id] }));
    const approvedNonBillable = {
      start_date,
      end_date,
      hours_approved,
      approved_hour_entries,
    };
    dispatch(updateUserNonBillable({ member_id, approvedNonBillable }));
  }
);

export const getUserApprovedNonBillable = createAsyncThunk(
  "users/getUserApprovedNonBillable",
  async ({ userId, startDate, endDate }, { dispatch }) => {
    const response = await getUsersApprovedNonBillableAPI({
      userId,
      startDate,
      endDate,
    });
    const { approvedNonBillable } = response.data;
    dispatch(
      updateUserNonBillable({
        member_id: userId,
        approvedNonBillable,
      })
    );
  }
);

export const getUsersTimeData = createAsyncThunk(
  "users/getUsersTimeData",
  async (payload, { getState, dispatch }) => {
    const defaultStartDate = dayjs()
      .startOf("week")
      .add(1, "day")
      .format("YYYY-MM-DD");
    const defaultEndDate = dayjs(defaultStartDate, "YYYY-MM-DD")
      .add(12, "week")
      .endOf("week")
      .format("YYYY-MM-DD");
    const { startDate = defaultStartDate, endDate = defaultEndDate, givenUserIds } =
      payload || {};
    const { users: { targetMembersByFeature: { [features.update_non_billable]: defaultUserIds = []} } = {} } = getState();
    const userIds = givenUserIds || defaultUserIds;
    const allUserIds = userIds.filter((userId) => {
      return !filterUsers.includes(Number(userId));
    }).join(",");
    const response = await getUsersTimeDataAPI({
      startDate,
      endDate,
      member_ids: allUserIds,
    });
    const userTimeData = response.data.data;
    dispatch(updateUsersTimeData(userTimeData));
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    all: {},
    teamMembers: {},
    targetMembersByFeature: {},
    billables: {},
    nonBillables: {},
    timeData: {},
  },
  reducers: {
    updateAllUsers: (state, { payload }) => {
      const { usersMap } = payload;
      state.all = usersMap;
    },
    updateTeamMembers: (state, { payload }) => {
      const { data: teamMemberList } = payload;
      const teamMembersObj = teamMemberList.reduce(
        (acc, memberData) => ({ ...acc, [memberData.id]: memberData }),
        {}
      );
      state.teamMembers = teamMembersObj;
    },
    updateTargetMembers: (state, { payload: { targetMemberIds, featureId } }) => {
      state.targetMembersByFeature[featureId] = targetMemberIds;
    },
    updateUserBillable: (state, { payload }) => {
      const { billable, userId } = payload;
      state.billables[userId] = billable;
    },
    updateUserNonBillable: (state, { payload }) => {
      const { member_id, approvedNonBillable } = payload;
      const { start_date, end_date, hours_approved } = approvedNonBillable;

      state.billables[member_id] = {
        ...(state.billables[member_id] || {}),
        non_billable_hours_approved: hours_approved,
      };

      const start_date_formatted = dayjs(start_date).format("YYYY-MM-DD");
      const end_date_formatted = dayjs(end_date).format("YYYY-MM-DD");
      state.nonBillables[member_id] = {
        ...(state.nonBillables[member_id] || {}),
        [`${start_date_formatted}|${end_date_formatted}`]: approvedNonBillable,
      };
    },
    updateUsersTimeData: (state, { payload }) => {
      Object.entries(payload).forEach(([member_id, member_time_data]) => {
        state.timeData = {
          ...state.timeData,
          [member_id]: {
            ...(state.timeData[member_id] || {}),
            ...member_time_data,
          },
        };
      });
    },
    updateNonBillableTimeData: (state, { payload }) => {
      const {
        start_date,
        end_date,
        member_id,
        non_billable_hours_approved,
        approved_hour_entries,
      } = payload;
      const start_date_formatted = dayjs(start_date).format("YYYY/MM/DD");
      const end_date_formatted = dayjs(end_date).format("YYYY/MM/DD");

      state.timeData[member_id] = {
        ...(state.timeData[member_id] || {}),
        [`${start_date_formatted}-${end_date_formatted}`]: {
          ...(state.timeData[member_id]?.[
            `${start_date_formatted}-${end_date_formatted}`
          ] || {}),
          non_billable_hours_approved,
          approved_hour_entries,
        },
      };
    },
  }
});
const { actions, reducer } = usersSlice;

export const {
  updateAllUsers,
  updateTeamMembers,
  updateUserBillable,
  updateUserNonBillable,
  updateUsersTimeData,
  updateNonBillableTimeData,
  updateTargetMembers,
} = actions;



export default reducer;

export const getAllTeamMembers = (state) => state.users.teamMembers;

