import TableRow from "../../../UIComponents/DesignSystem/TableRow.js";
import IconButton from "../../../UIComponents/DesignSystem/IconButtton.js";
import TableCell from "../../../UIComponents/DesignSystem/TableCell.js";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material";
import { Collapse } from "@mui/material";
import { Stack, Typography } from "../../../UIComponents/index.js";
import {
  assigned_certification_mapping,
  certification_mapping,
  user_mapping,
} from "./CollapsibleTable.js";
import { useSelector } from "react-redux";
import { selectCertificationById } from "../../../redux/reducers/certificationDashboard.js";
import CertificationDetails from "./CertificationDetails.js";

const UserRow = ({ userId, expandUser, handleUserExpandToggle }) => {
  const user = useSelector((state) => selectCertificationById(state, userId));
  return (
    <>
      <TableRow key={userId}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() =>
              handleUserExpandToggle({
                userId: userId,
              })
            }
          >
            {expandUser[userId] ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
        {Object.keys(user_mapping).map((key) => {
          return (
            <TableCell key={key}>
              <Stack alignItems="center" justifyContent="center">
                <Typography>{`${user[key] ?? "-"}`}</Typography>
              </Stack>
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        <TableCell sx={{ padding: 0 }} colSpan={7}>
          <Collapse in={expandUser[userId]} timeout="auto" unmountOnExit>
            <CertificationDetails
              userId={userId}
              title="Acquired Certifications"
              headerColMap={certification_mapping}
              certification_data={user.certificates}
            />
            <CertificationDetails
              userId={userId}
              title="Assigned Certifications"
              headerColMap={assigned_certification_mapping}
              certification_data={user.assigned_certificates}
              showAssignIcon
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default UserRow;
