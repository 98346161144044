import TableRow from "../../../UIComponents/DesignSystem/TableRow.js";
import IconButton from "../../../UIComponents/DesignSystem/IconButtton.js";
import TableCell from "../../../UIComponents/DesignSystem/TableCell.js";
import { useDrawer } from "./useDrawer.js";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Collapse } from "@mui/material";

import { Stack, Tooltip, Typography } from "../../../UIComponents/index.js";
import ProjectHealthReports from "./ProjectHealthReport.js";
import dayjs from "dayjs";
import { current_state_fields_mapping } from "./CollapsibleTable.js";

export const getPMGradeColor = ({ pm_grade }) => {
  switch (pm_grade) {
    case "A+":
    case "A":
      return "green";
    case "B":
    case "C":
      return "orange";
    case "F":
      return "red";
    default:
      return "black";
  }
};

const ProjectRow = ({
  project,
  openProject,
  handleProjectClick,
  historicalReportIds,
}) => {
  const { openDrawer } = useDrawer();
  const daysSinceStartDate = dayjs(dayjs()).diff(
    project.project_start_date,
    "day"
  );
  const isSubmitDisabled =
    daysSinceStartDate < 0 ||
    (project.days_since_last_report < 7 &&
      daysSinceStartDate > project.days_since_last_report);

  let disabledReason = "";
  if (daysSinceStartDate < 0) {
    disabledReason = "Start date for the project is greater than today's date.";
  } else if (project.days_since_last_report < 7) {
    disabledReason =
      "A report has already been submitted for the current week.";
  }

  const handleDrawerClick = () => {
    openDrawer(project);
  };
  return (
    <>
      <TableRow key={project.project_id}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() =>
              handleProjectClick({
                project_id: project.project_id,
                historicalReportIds,
              })
            }
          >
            {openProject[project.project_id] ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell>
          <Typography
            variant="caption"
            color="primary"
            component={"a"}
            href={`https://magna.mavenlink.com/workspaces/${project.project_id}`}
            target="_blank"
          >
            {project.project_title}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography>{project.manager_name || "-"}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{project.project_lead}</Typography>
        </TableCell>
        {Object.keys(current_state_fields_mapping).map((key) => {
          return (
            <TableCell key={key}>
              <Stack alignItems="center" justifyContent="center">
                {key === "pm_grade" ? (
                  <Typography
                    color={getPMGradeColor({ pm_grade: project[key] })}
                    fontWeight={900}
                  >{`${project[key]}`}</Typography>
                ) : (
                  <Typography>{`${project[key]}`}</Typography>
                )}
              </Stack>
            </TableCell>
          );
        })}
        <TableCell>
          <Stack alignItems="end">
            <Tooltip title={isSubmitDisabled ? disabledReason : ""}>
              <span>
                <IconButton
                  aria-label="submit report"
                  size="small"
                  onClick={handleDrawerClick}
                  disabled={isSubmitDisabled}
                  sx={{ maxWidth: "36px" }}
                >
                  <AddIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ padding: 0 }} colSpan={19}>
          <Collapse
            in={openProject[project.project_id]}
            timeout="auto"
            unmountOnExit
          >
            <ProjectHealthReports historicalReportIds={historicalReportIds} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ProjectRow;
