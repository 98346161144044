import React from "react";
import { render } from "react-dom";
import App from "./App";
import "bulma/css/bulma.css"; /** Only used in /add-auth */
import "bootstrap/dist/css/bootstrap.min.css";
import { createTheme, ThemeProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import configAppStore from "./redux/store";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";

const theme = createTheme({
  palette: {
    border: {
      main: '#646FA740',
    },
    primary: {
      main: "#BD352A",
      light: "#BD352A10",
      dark: "#93190F",
      contrastText: "#fff",
    },
    primaryLight: {
      main: "#BD352A10",
      dark: "#BD352A20",
      contrastText: "#BD352A",
    },
    secondary: {
      main: "#646FA7",
      light: "#646FA720",
      dark: "#282736",
      contrastText: "#fcf9ff",
    },
    secondaryLight: {
      main: "#646FA710",
      dark: "#646FA720",
      contrastText: "#646FA7",
    },
    success: {
      main: "#2e7d32",
      light: "#4caf5020",
      dark: "#1b5e20",
    },
    info: {
      main: "#0288d1",
      light: "#03a9f4",
      dark: "#01579b",
    },
    warning: {
      main: "#ed6c02",
      light: "#ff9800",
      dark: "#e65100",
    },
    error: { main: "#d32f2f", light: "#ef5350", dark: "#c62828" },
    disabled: {
      main: '#2A304940'
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          input: {
            fontSize: "12px",
          },
          textarea: {
            fontSize: "12px",
            overflow: "auto !important",
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          opacity: "1 !important",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.component === "th" && {
            fontWeight: 600,
          }),
          fontFamily: "Montserrat, Roboto, Helvetica, Arial, sans-serif",
          padding: "6px 16px",
          ...(ownerState.size === "verysmall" && {
            padding: "4px 8px",
            fontSize: "0.785rem",
          }),
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          "& .MuiTabs-indicator": {
            backgroundColor: "#7367F0 !important", // Customize the tab indicator color
          },
        },
        flexContainer: {
          "& .Mui-selected": {
            color: "#7367F0 !important", // Customize the selected tab text color
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        fontSize: "12px",
        variantMapping: {
          Regular: "p",
          Medium: "p",
          SemiBold: "h6",
          h1: 'h1',
        },
      },
    },
  },
  typography: {
    Regular: {
      fontFamily: "Montserrat, Roboto, Helvetica, Arial, sans-serif",
      fontSize: "12px",
      lineHeight: "20px",
      letterSpacing: 0,
      fontWeight: 400,
      color: "#5E5873",
    },
    Medium: {
      fontFamily: "Montserrat, Roboto, Helvetica, Arial, sans-serif",
      fontSize: "12px",
      lineHeight: "20px",
      letterSpacing: "0.6px",
      color: "#5E5873",
      fontWeight: 500,
    },
    SemiBold: {
      fontFamily: "Montserrat, Roboto, Helvetica, Arial, sans-serif",
      fontSize: "12px",
      lineHeight: "18px",
      letterSpacing: 0,
      color: "#00CFE8",
      fontWeight: 600,
    },
    // useNextVariants: true,
  },
});

export const { store } = configAppStore();

render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <ToastContainer />
            <App />
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
