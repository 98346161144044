export function calculateEmployeeExperience(startDate) {
  const today = new Date();
  const start = new Date(startDate);

  console.log({ startDate, start });

  // Ensure start date is not in the future
  if (start > today) {
    console.error("Start date is in the future.");
    return;
  }

  // Calculate differences in years, months, and days
  let years = today.getFullYear() - start.getFullYear();
  let months = today.getMonth() - start.getMonth();
  let days = today.getDate() - start.getDate();

  // Adjust for cases where the current month/day is less than the start month/day
  if (months < 0 || (months === 0 && days < 0)) {
    years--;
    months += 12; // Carry over the year into months
  }
  if (days < 0) {
    months--;
  }

  // Format output based on the duration of experience
  let result = "";
  if (years > 0) {
    result += `${years} year${years > 1 ? "s" : ""}`;
  }
  if (months > 0 && years > 0) {
    result += ` ${months} month${months > 1 ? "s" : ""}`;
  } else if (months > 0) {
    result = `${months} month${months > 1 ? "s" : ""}`;
  }

  // Handle days if no months or years are accrued
  if (!result) {
    const oneDay = 1000 * 60 * 60 * 24;
    const diffInMs = today - start;
    const diffInDays = Math.floor(diffInMs / oneDay);
    if (diffInDays > 0) {
      result = `${diffInDays} day${diffInDays > 1 ? "s" : ""}`;
    } else {
      result = "0 days";
    }
  }

  return result;
}
