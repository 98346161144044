import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { getAllLeadsAPI } from "../../apis/User/index.js";

const leadsAdapter = createEntityAdapter({
  selectId: (lead) => lead.id,
  sortComparer: (a, b) => a.title.localeCompare(b.title),
});

// Thunk to fetch all leads
export const fetchAllLeads = createAsyncThunk(
  "members/leads/fetchAll",
  async () => {
    const leads = await getAllLeadsAPI();
    return leads;
  }
);

// Create leads slice
const leadsSlice = createSlice({
  name: "leads",
  initialState: leadsAdapter.getInitialState({
    loading: false,
    error: null,
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllLeads.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllLeads.fulfilled, (state, action) => {
        state.loading = false;
        leadsAdapter.setAll(state, action.payload);
      })
      .addCase(fetchAllLeads.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export the generated selectors for leads
export const {
  selectAll: selectAllLeads,
  selectById: selectLeadById,
  selectIds: selectLeadIds,
} = leadsAdapter.getSelectors((state) => state.members.leads);

// Export the reducer to be combined in the members slice
export default leadsSlice.reducer;
