/* eslint-disable react-hooks/exhaustive-deps */
import {
  Stack,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../../UIComponents/index.js";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeliverableItem from "./DeliverableItem.js";
import { useSelector } from "react-redux";
import { useContext, useMemo, useState } from "react";
import { ValidationContext, get_closing_deliverable_modal } from "./DecisionTrackerForm.js";

const sortAndSeperateSelectedDeliverables = ({ deliverables_arr,  discussedDeliverablesIds }) => {
  const ns_deliverbales_temp = [], s_deliverbales_temp = [], c_deliverbales_temp = [] ;
  const selected_ns_deliverbales_temp = [], selected_s_deliverbales_temp = [], selected_c_deliverbales_temp = [] ;

  deliverables_arr.forEach(deliverable => {
    if(discussedDeliverablesIds.includes(deliverable.id)) {
      if(deliverable.state === 'started') selected_s_deliverbales_temp.push(deliverable)
      else if(deliverable.state === 'not started') selected_ns_deliverbales_temp.push(deliverable)
      else selected_c_deliverbales_temp.push(deliverable);
    }
    else {
      if(deliverable.state === 'started') s_deliverbales_temp.push(deliverable)
      else if(deliverable.state === 'not started') ns_deliverbales_temp.push(deliverable)
      else c_deliverbales_temp.push(deliverable);
    };
  });

  const sorted_unselected_deliverbales_temp = [...s_deliverbales_temp, ...ns_deliverbales_temp, ...c_deliverbales_temp];
  const sorted_selected_deliverables_temp = [...selected_s_deliverbales_temp, ...selected_ns_deliverbales_temp, ...selected_c_deliverbales_temp];
  return [...sorted_selected_deliverables_temp, ...sorted_unselected_deliverbales_temp];
};



const Deliverables = ({ title, workspace_id, meeting_id, discussedDeliverablesIds }) => {
  const deliverables = useSelector(state => state.workspaces.deliverables[workspace_id]) || {};
  const deliverables_arr = Object.values(deliverables);
  const [expanded, setExpanded] = useState(false);
  const deliverables_arr_sorted = useMemo(() => (() => sortAndSeperateSelectedDeliverables({ deliverables_arr, discussedDeliverablesIds  }))(), [discussedDeliverablesIds.length]);

  const { d_t_validation: { unsaved_deliverable_notes_ids_by_meeting }, setModalState } = useContext(ValidationContext);
  const meeting_unsaved_deliverable_notes = unsaved_deliverable_notes_ids_by_meeting[meeting_id];


  return (
    <Stack sx={{ width: '-webkit-fill-available' }}>
      <Accordion
        elevation={0} 
        variant="outlined" 
        disableGutters 
        sx={{ 
          borderRadius: '8px',
          '&:before': {
            height: 0,
          }
        }}
        TransitionProps= {{ unmountOnExit: true }}
        expanded={expanded}
        onChange={() => {
          setExpanded(prev => {            
            if(meeting_unsaved_deliverable_notes?.length) {
              if(expanded) setModalState(get_closing_deliverable_modal({ setModalState }));
              return true;
            }
            return !prev
          });
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} >
          <Typography fontWeight={700}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack
            direction='column'
            alignContent='start'
            sx={{
              maxHeight: '450px',
              overflowY: 'auto',
              padding: '4px 8px 0 4px',
            }}  
            gap={1}>
            {deliverables_arr_sorted.map((data) => (
              <DeliverableItem key={data.id} deliverable_id={data.id} meeting_id={meeting_id} workspace_id={workspace_id} isDiscussed={discussedDeliverablesIds.includes(data.id)} />
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

export default Deliverables;
