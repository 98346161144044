import dayjs from "dayjs";
import { httpCall } from "../httpCalls"


export const publishMeetingNotesAPI = async ({ meeting_id, workspace_id, post_id, meeting_note_summary, deliverable_notes_summary_map }) => {
    const workspace_meeting_response = await httpCall({
        type: 'POST',
        url: `decisions/publish_meeting_notes/${meeting_id}`,
        data: {
            workspace_id, 
            meeting_note_summary, 
            deliverable_notes_summary_map,
            post_id,
        }
    });
    const result = workspace_meeting_response.data;
    return result;
};

export const getMeetingsByWorkspaceAPI = async ({ workspace_id }) => {
    const workspace_meetings_response = await httpCall({
        type: 'GET',
        url: 'decisions/meetings',
        data: {
            workspace_id,
        }
    });
    const workspace_meetings = workspace_meetings_response.data.data;
    return workspace_meetings;
}

export const createNewMeetingAPI = async ({ workspace_id }) => {
    const default_meeting_payload = {
        workspace_id,
        general_notes: "",
        magna_next_steps: "",
        client_next_steps: "",
        title: dayjs().format('MMMM D, YYYY h:mm A'),
        agenda: "",
    }
    const meeting_payload_resposne = await httpCall({
        type: 'POST',
        url: 'decisions/meetings',
        data: default_meeting_payload,
    });
    const [workspace_meeting] = meeting_payload_resposne.data.data;
    return workspace_meeting;
}

export const updateWorkspaceMeetingAPI = async ({ meeting_id, client_next_steps, magna_next_steps, general_notes, agenda, discussed_deliverables_ids, title }) => {
    const workspace_meeting_response = await httpCall({
        type: 'PUT',
        url: `decisions/meetings/${meeting_id}`,
        data: {
            client_next_steps,
            magna_next_steps,
            general_notes,
            agenda,
            discussed_deliverables_ids,
            title
        }
    });
    const [workspace_meeting] = workspace_meeting_response.data.data;
    return workspace_meeting;
};

export const getDeliverablesNotesByWorkspaceAPI = async ({ workspace_id }) => {
    const workspace_meetings_response = await httpCall({
        type: 'GET',
        url: 'decisions/deliverables_notes',
        data: {
            workspace_id,
        }
    });
    const workspace_meetings = workspace_meetings_response.data.data;
    return workspace_meetings;
}

export const createNewDeliverableNoteAPI = async ({ workspace_id, deliverable_id, meeting_id, note_content }) => {
    const deliverable_note_response = await httpCall({
        type: 'POST',
        url: 'decisions/deliverables_notes',
        data: {
            workspace_id,
            deliverable_id,
            meeting_id,
            note_content,
        }
    });
    const [deliverable_note] = deliverable_note_response.data.data;
    return deliverable_note;
}

export const updateDeliverableNoteAPI = async ({ note_id, note_content }) => {
    const deliverable_note_response = await httpCall({
        type: 'PUT',
        url: `decisions/deliverables_notes/${note_id}`,
        data: {
            note_content
        }
    });
    const [deliverable_note] = deliverable_note_response.data.data;
    return deliverable_note;
}