import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { CircularProgress } from "@mui/material";
import EmployeeRow from "./EmployeeRow.js";
import TableCell from "../../UIComponents/DesignSystem/TableCell.js";
import TableRow from "../../UIComponents/DesignSystem/TableRow.js";
import TableHead from "../../UIComponents/DesignSystem/TableHead.js";

const ProjectUtilisationTable = (props) => {
  const { dataByManager = {}, loadingState, handleReportClick } = props;

  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table aria-label="Projects grouped by managers">
          <TableHead>
            <TableRow>
              <TableCell size="verysmall">Manager</TableCell>
              <TableCell size="verysmall">Resource</TableCell>
              <TableCell size="verysmall">Start Date</TableCell>
              <TableCell size="verysmall">Magna Age</TableCell>
              {/* <TableCell size="verysmall">End Date</TableCell> */}
              <TableCell size="verysmall">Total PTO Hours</TableCell>
              <TableCell size="verysmall" title="Billable Hours">
                Total Holiday Hours
              </TableCell>
              <TableCell size="verysmall">Total Scheduled Hours</TableCell>
              <TableCell size="verysmall">Total Available Hours</TableCell>
              <TableCell size="verysmall">Total Logged Hours</TableCell>
              <TableCell size="verysmall">Total Billed Hours</TableCell>
              {/* <TableCell size="verysmall">Total Scheduled Hours</TableCell> */}
              <TableCell size="verysmall" title="Current Non Billable Hours">
                Total Billable Utilization
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loadingState === "loading" && (
              <TableRow>
                <TableCell
                  colSpan={13}
                  style={{ textAlign: "center", height: 300 }}
                >
                  <CircularProgress color="primary" />
                </TableCell>
              </TableRow>
            )}
            {loadingState === "failed" && (
              <TableRow>
                <TableCell
                  colSpan={13}
                  style={{ textAlign: "center", height: 300 }}
                >
                  Error in loading the data! Please try again later.
                </TableCell>
              </TableRow>
            )}
            {loadingState === "success" &&
              Object.values(dataByManager)[0].length === 0 && (
                <TableRow>
                  <TableCell
                    colSpan={13}
                    style={{ textAlign: "center", height: 300 }}
                  >
                    No Records found!
                  </TableCell>
                </TableRow>
              )}

            {loadingState === "success" &&
              Object.values(dataByManager).map(({ details }, i) => (
                <EmployeeRow
                  key={`Proj-${i}`}
                  handleReportClick={handleReportClick}
                  employeeUtilisation={details}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

export default ProjectUtilisationTable;
