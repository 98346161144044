import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import moment from "moment";
import { Grid } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function TimeUtilization(props) {
  const { data } = props;
  const labels = data.map((item) =>
    moment(item.date_performed).format("DD MMM")
  );

  const chartData = {
    labels,
    datasets: [
      {
        label: "Billable Utilization",
        data: data.map((item) =>
          item.billable_hours_utilization > 100
            ? 100
            : item.billable_hours_utilization
        ),
        borderColor: "#28C76F",
        backgroundColor: "#28C76F1f",
      },
      {
        label: "Scheduled Utilization",
        data: data.map((item) =>
          item.scheduled_hours_utilization > 100
            ? 100
            : item.scheduled_hours_utilization
        ),
        borderColor: "#7367F0",
        backgroundColor: "#7367F01f",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: "top",
        labels: {
          fontColor: "#333",
          usePointStyle: true,
        },
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: "Timeline",
          color: "#5E5873",
          font: {
            size: 12,
            weight: "bold",
          },
        },
        grid: {
          drawBorder: false,
          drawTicks: true,
          lineWidth: (context) => {
            return 0; // hide the Horizontal grid line
          },
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: "Utilization (%)",
          color: "#5E5873",
          font: {
            size: 12,
            weight: "bold",
          },
        },
        min: 0,
        max: 120,
        ticks: {
          stepSize: 20,
          callback: (value, index, values) => {
            if (value === 120) {
              return ""; // Hide the '120' label
            }
            return value;
          },
        },
        grid: {
          drawBorder: false,
          drawTicks: true,
          color: (context) => {
            if (context.tick.value === 120) {
              return "rgba(0, 0, 0, 0)";
            }
            if (context.tick.value === 100) {
              return "#00CFE8";
            }
            return "#ddd"; // Other grid lines color
          },
          lineWidth: (context) => {
            if (context.tick.value === 120) {
              return 0; // Hide the grid line at 120 max value
            }
            if (context.tick.value === 100) {
              return 0.75; // Highlight the grid line at 100%
            }
            return 0.5; // Other grid lines width
          },
        },
      },
    },
  };

  return (
    <Grid sx={{ maxHeight: 500, width: "100%" }}>
      <Line options={options} data={chartData} height={400} width={'100%'}/>
    </Grid>
  );
}
