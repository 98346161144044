import { Typography, useTheme } from "@mui/material";
import { StyledProjectDetailsWrapper } from "./StyledComponents";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllProjects } from "../../redux/reducers/workspaces";
import CircularProgress from "../../ComponentsLibrary/CircularProgress";
import { Stack } from "../../UIComponents";
import Button from "../../UIComponents/DesignSystem/Button";

const ProjectDetails = ({ workspaceId, handleClose }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const current_workspace = useSelector((state) => state.workspaces.all[workspaceId]);
  const { title, percent_of_budget_used, budgeted_hours = 0, used_budgeted_hours, this_month_budgeted_hours = 0,  this_week_budgeted_hours = 0 } = current_workspace || {};
  const hours_remaining = budgeted_hours - used_budgeted_hours;

  const percent_of_budget_used_this_week = ((this_week_budgeted_hours / (budgeted_hours ?? 1)) * 100).toFixed(2);
  const percent_of_budget_used_this_month = ((this_month_budgeted_hours / (budgeted_hours ?? 1)) * 100).toFixed(2);


  const allTimeChartData = [{ id: 0, value: parseFloat(used_budgeted_hours ?? 0), color: theme.palette.primary.main, title: 'Hours Used' }, { id: 1, value: hours_remaining, title: 'Hours Remaining', color: theme.palette.secondary.main }];
  const thisWeekChartData = [{ id: 0, value: parseFloat(this_week_budgeted_hours ?? 0) , color: theme.palette.primary.main, title: 'Hours Used' }, { id: 1, value: hours_remaining, title: 'Hours Remaining', color: theme.palette.secondary.main }];
  const thisMonthChartData = [{ id: 0, value: parseFloat(this_month_budgeted_hours ?? 0), color: theme.palette.primary.main, title: 'Hours Used' }, { id: 1, value: hours_remaining, title: 'Hours Remaining', color: theme.palette.secondary.main }];

  useEffect(() => {
    if(!current_workspace) dispatch(getAllProjects());
  }, [workspaceId, dispatch, current_workspace])

  return (
    <StyledProjectDetailsWrapper flexDirection="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={4} sx={{ width: '-webkit-fill-available'}}>
        <Stack
          flexDirection="row"
          alignItems='center'
          justifyContent='space-between'
          gap={2}>
        <Typography sx={{
          fontSize: '1.5rem',
          fontWeight: 700,
        }}>{title}</Typography>
        {/* <Chip label="Project" color="primaryLight" size="small"/> */}
        </Stack>
        <Stack direction="row" gap={2}>
          <CircularProgress
            centerText={`${percent_of_budget_used}%`}            
            label={`All Time
            ${used_budgeted_hours}/${budgeted_hours}hrs`}
            chartData={allTimeChartData}
          />
          <CircularProgress
            label="This Month" 
            chartData={thisMonthChartData}
            centerText={`${percent_of_budget_used_this_month}%`}
          />
          <CircularProgress
            label="This Week" 
            chartData={thisWeekChartData}
            centerText={`${percent_of_budget_used_this_week}%`}
          />
        </Stack>
        <Button
          color='primaryLight'
          variant='contained'
          size='large'
          onClick={handleClose}>
          <Typography>Close</Typography>
        </Button> 
      </Stack>
    </StyledProjectDetailsWrapper>
  );
};

export default ProjectDetails;
