import React, { useEffect, useState } from "react";
// import PropTypes from "prop-types";
import { Stack, SvgIcon, Typography, createSvgIcon } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import axios from "axios";
import { useAuth } from "../../CustomHooks/AuthenticationHook";

const OrgIcon = createSvgIcon(
  <svg
    width="20"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.875 18.125L2.375 15V4.99997L9.875 1.87497V18.125ZM1.26875 16.57C1.04106 16.475 0.846563 16.3148 0.709764 16.1094C0.572965 15.9041 0.499979 15.6629 0.5 15.4162V4.58372C0.499979 4.33701 0.572965 4.09581 0.709764 3.8905C0.846563 3.68519 1.04106 3.52496 1.26875 3.42997L9.15375 0.143722C9.43873 0.0249637 9.74862 -0.0215885 10.0559 0.00819875C10.3632 0.037986 10.6584 0.143191 10.9152 0.314472C11.1721 0.485754 11.3827 0.717811 11.5283 0.990039C11.6739 1.26227 11.7501 1.56624 11.75 1.87497V2.49997H13.3125C14.52 2.49997 15.5 3.47872 15.5 4.68747V15.3125C15.5 15.8926 15.2695 16.449 14.8593 16.8593C14.4491 17.2695 13.8927 17.5 13.3125 17.5H11.75V18.125C11.7501 18.4337 11.6739 18.7377 11.5283 19.0099C11.3827 19.2821 11.1721 19.5142 10.9152 19.6855C10.6584 19.8568 10.3632 19.962 10.0559 19.9917C9.74862 20.0215 9.43873 19.975 9.15375 19.8562L1.26875 16.57ZM11.75 15.625H13.3125C13.3954 15.625 13.4749 15.592 13.5335 15.5334C13.5921 15.4748 13.625 15.3954 13.625 15.3125V4.68747C13.625 4.60459 13.5921 4.52511 13.5335 4.4665C13.4749 4.4079 13.3954 4.37497 13.3125 4.37497H11.75V15.625Z"
    />
  </svg>,
  "Icon"
);

const IconComponent = (props) => (
  <div
    style={{
      backgroundColor: props.background,
      width: 42,
      height: 42,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 5,
    }}
  >
    <SvgIcon sx={{ color: props.background }} inheritViewBox>
      {props.Icon}
    </SvgIcon>
  </div>
);

const PortFolio = (props) => {
  const [projects, setProjects] = useState([]);
  const { user } = useAuth();
  const colorPallette = ["#7367f0", "#00CFE8", "#28C76F", "#FF9F43", "#EA5455"];

  useEffect(() => {
    axios
      .get(`${`${process.env.REACT_APP_PROD}/api/v1`}/portfolio/${user.userId}`)
      .then((res) => {
        setProjects(res.data.data);
      })
      .catch((err) => console.log(err));
  }, [user.userId]);

  return (
    <Stack direction={"column"} spacing={2}>
      {projects.map((proj, i) => (
        <Stack
          key={`${proj.workspace_id}`}
          direction={"row"}
          spacing={1}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Stack direction={"row"} spacing={1}>
            <IconComponent
              background={`${colorPallette[i % 5]}1f`}
              Icon={
                <OrgIcon fontSize="small" sx={{ fill: colorPallette[i % 5] }} />
              }
            />
            <Stack direction={"column"}>
              <Typography variant="Medium" fontSize={14} pr={1}>
                {proj.workspace_title}
              </Typography>
              <Typography variant="Regular" sx={{ color: "text.disabled" }}>
                {proj.role_name}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction={"row"} alignItems={"center"}>
            <Typography variant="Medium" fontSize={14} pr={2}>
              {proj.five_star_review}
            </Typography>
            <ArrowForwardIosIcon
              fontSize="small"
              sx={{ color: "text.disabled", fontSize: 14 }}
            />
          </Stack>
        </Stack>
      ))}
      {projects.length===0 && <Typography variant="Medium">No Projects Found</Typography>}
    </Stack>
  );
};

// PortFolio.propTypes = {};

export default PortFolio;
