import { toast } from "react-toastify";

export async function copyHTMLasRichText({ htmlString }) {
    try {
        await navigator.clipboard.write([
            new ClipboardItem({
                "text/html": new Blob([htmlString], { type: "text/html" }),
                "text/plain": new Blob([htmlString], { type: "text/plain" })
            })
        ]);
        toast('Copied to clipboard');
    } catch(e) {
        toast.error('Could not copy');
    }
};