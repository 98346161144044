
import { styled } from "@mui/material";
import { Table as TableComp } from "../index.js"; 

const StyledTable = styled(TableComp)(({ theme }) => ({
}));

const Table = (props) => {
    return ( 
        <StyledTable {...props} />
     );
}
 
export default Table;