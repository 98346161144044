import React, { Fragment, useState } from "react";
//import PropTypes from "prop-types";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Popover,
  Stack,
  TableCell,
  Typography,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

export const CustomTableHeader = (props) => {
  const { label, filterable, varName, options, selectedOptions, onSave } =
    props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [selected, setSelected] = useState(selectedOptions);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheck = (event) => {
    const { checked, value } = event.target;
    let checkedOptions = selected;
    if (checked) {
      checkedOptions.push(value);
      setSelected((prev) => [...new Set(checkedOptions)]);
    } else {
      setSelected((prev) => prev.filter((opt) => opt !== value));
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? `${label}-popover` : undefined;

  const handleSave = () => {
    onSave(selected, varName);
    handleClose();
  };
  return (
    <Fragment>
      <TableCell size="verysmall">
        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>          
        {label}
        {filterable && (
          <IconButton
            aria-label="filter data"
            disableFocusRipple
            disableRipple
            disableTouchRipple
            sx={{ p: 0 }}
            onClick={handleClick}            
          >
            <FilterAltIcon              
              sx={{
                color: selectedOptions.length > 0 ? "#ffbd00" : "#000",
                fontSize:{sm:12,md:15,xl:20}
              }}
            />
          </IconButton>
        )}
        </Stack>
      </TableCell>
      {filterable && open &&(
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", p: 1 }}>
            <Typography variant="Regular">Select values from below</Typography>
            <FormGroup>
              {options.map((option, index) => (
                <FormControlLabel
                  key={`filt-opt-${index}-${option}`}
                  control={
                    <Checkbox
                      size="small"
                      name={option}
                      value={option}
                      checked={selected?.includes(option)}
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                      onChange={handleCheck}
                    />
                  }
                  disableTypography
                  label={
                    <Typography variant="Regular">                      
                      {option && option !== "null" ? option : "Unassigned"}
                    </Typography>
                  }
                />
              ))}
            </FormGroup>
          </Box>
          <Divider />
          <Stack
            direction={"row"}
            justifyContent={"space-around"}
            spacing={2}
            p={1}
          >
            <Button
              size="small"
              sx={{ textTransform: "capitalize" }}
              onClick={handleClose}
            >
              <Typography variant="Regular">Cancel</Typography>
            </Button>
            <Button
              size="small"
              variant="outlined"
              sx={{ textTransform: "capitalize" }}
              onClick={handleSave}
              disabled={selected?.length === 0 && selectedOptions?.length === 0}
            >
              <Typography variant="Regular">Save</Typography>
            </Button>
          </Stack>
        </Popover>
      )}
    </Fragment>
  );
};
export const CustomTable = (props) => {
  return <div></div>;
};

CustomTable.propTypes = {};

//export default CustomTable;
