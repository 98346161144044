import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
const ChangePass = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [clients, setClients] = useState([]);
    const [msg, setMsg] = useState([]);
    const [msg1, setMsg1] = useState([]);
    const [color, setColor] = useState([]);
    const [client_id, setClient_id] = useState('');
    const [app_name, setApp_name] = useState('');
    const [secret_key, setSecret_key] = useState('');
    const [client_name, setClient_name] = useState('');
    const [account, setAccount] = useState('');
    const [ips,setIps] = useState()
    const [inp,setInp] = useState({display:"none"})

  
    const navigate = useNavigate();
    
    useEffect(() => {
        refreshToken()
        axios({
            method: 'get',
            url: `https://ipapi.co/json/`,
            withCredentials: false,
          }).then((res)=>{
                setIps(`ip:${res.data.ip},country: ${res.data.country_name},city:${res.data.city},postal:${res.data.postal},lat_lon:Lat: "${res.data.latitude} | Lon: ${res.data.longitude},Provider: ${res.data.org}`)
            }).catch((err)=>{
                console.log("errr",err)
            })
    }, []);
   
    const refreshToken = async () => {
        try {
            const response = await axios.get(`${`${process.env.REACT_APP_PROD}/api/v1`}/token`);
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setEmail(decoded.email);
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                navigate("/");
                console.log("ERRORRRRR")
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${`${process.env.REACT_APP_PROD}/api/v1`}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
         Promise.reject(error);
    });

    const config = { headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${token}`
    } }

    const ChangePass = async (e) => {
        e.preventDefault();
           
        if(oldPassword=='' || newPassword==''){
            setColor("notification is-danger is-center")
            return toast.error("ERROR! fields cannot be empty.")
        }
        try {
            await axios.post(`${`${process.env.REACT_APP_PROD}/api/v1`}/change-password`, {
                oldpassword:oldPassword,
                newpassword:newPassword,
                email:email,
                ips
            },config)
            setOldPassword("")
            setNewPassword("")
            toast("Password has been changed!")
        } catch (error) {
            if (error.response) {
                toast.error(error.response.data.msg);
            }
        }
    }


    const refreshPage = () => {
        navigate(0)
    }


    return (
        <>
            <section className="hero  ">
              
            <div className="hero-body ">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-4-desktop has-background-grey-light">
                       
                            <form  className="box" onSubmit={ChangePass}>
                            <h1 style={{fontSize:"25px",textAlign:"center",fontWeight:"700"}}>Change Password</h1>
                              
                                <div className="field ">
                                    <label className="label">Current Password</label>
                                    <div className="controls">
                                        <input type="password" className="input" placeholder="*****" value={oldPassword}  onChange={(e)=>setOldPassword(e.target.value)} />
                                        <span style={{color:"#4f5256",fontSize:"12px"}}>Current password of this<b> APP</b> .</span><br />
                                        </div>
                                </div>

                                <div className="field ">
                                    <label className="label">New Password</label>
                                    <div className="controls">
                                        <input type="password" className="input" placeholder="*****" value={newPassword}  onChange={(e)=>setNewPassword(e.target.value)} />
                                        <span style={{color:"#4f5256",fontSize:"12px"}}>Current password of this<b> APP</b> .</span><br />
                                        </div>
                                </div>
                               
                               
                               
                                <div className="field ">
                                    <button type="submit" className="button is-info is-fullwidth">ADD NOW</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


        </section>
        
        </>
    )
}

export default ChangePass
