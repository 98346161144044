import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Tooltip, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

// const BlueOnGreenTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
// ))(`
//     color: lightblue;
//     background-color: green;
// `);

const InformaticTitle = (props) => {
  const { label, titleinfo } = props;

  function renderTitle() {
    if (typeof titleinfo === "string") {
      return (
        <Typography variant="Medium" fontSize={10} sx={{ color: "#FFF",p:1 }}>
          {titleinfo}
        </Typography>
      );
    }
    // Check if titleinfo is a React element
    if (React.isValidElement(titleinfo)) {
      return <Fragment>{titleinfo}</Fragment>;
    }
    // Handle other cases if needed
    return <h6>Invalid titleinfo format</h6>;
  }
  return (
    <Typography
      variant="Medium"
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      sx={{ color: "text.disabled", textTransform: "uppercase" }}
      {...props}
    >
      {label}
      <Tooltip title={renderTitle()} placement="top" arrow>
        <InfoIcon
          fontSize="medium"
          sx={{ color: "text.disabled", pl: 1, fontSize: "1.25rem" }}
        />
      </Tooltip>
    </Typography>
  );
};

InformaticTitle.propTypes = {
  label: PropTypes.string.isRequired,
  titleinfo: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default InformaticTitle;
