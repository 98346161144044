import * as React from "react";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import UpdateIcon from "@mui/icons-material/Update";
import { useNavigate, Outlet } from "react-router-dom";
import ListSubheader from "@mui/material/ListSubheader";
import PropTypes from "prop-types";
import Collapse from "@mui/material/Collapse";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import AppBarMtg from "./AppBar";
import { Grid, SvgIcon, Typography } from "@mui/material";
import { useAuth } from "../CustomHooks/AuthenticationHook";
import { ReactComponent as DashboardIcon } from "../assets/DashboardIcon.svg";
import { ReactComponent as ProfileIcon } from "../assets/ProfileIcon.svg";
import { ReactComponent as AuthentocatorIcon } from "../assets/AuthentocatorIcon-copy.svg";
import { ReactComponent as ReportsIcon } from "../assets/ReportsIcon.svg";
import { styled, useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const drawerWidth = 240;
const minDrawerWidth = 58;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open && {
    marginLeft: minDrawerWidth,
    width: `calc(100% - ${minDrawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: minDrawerWidth,
  [theme.breakpoints.up("sm")]: {
    width: minDrawerWidth,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme, open }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: open ? "flex-end" : "center",
  justifyContent: open ? "flex-end" : "center",
  padding: theme.spacing(0, 1),
}));

const IconComponent = (props) => (
  <SvgIcon viewBox="0 0 18 18">{props.children}</SvgIcon>
);

const NavList = (props) => {
  const navigate = useNavigate();
  const { isParentCollapsed = false, openParentDrawer } = props;
  const [open, setOpen] = React.useState(false);
  const childrenList = props.navigationList.map((ele, i) => {
    if (ele.RouteAccess || process.env.REACT_APP_ENV === "development") {
      return (
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          key={`Nav-${i}-${ele.RouteName}`}
        >
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 3 }}
              onClick={() => {
                navigateLink(ele.RouteLink);
                if (ele.ClickFn) ele.ClickFn();
              }}
            >
              {/* <ListItemIcon sx={{minWidth:40}}>{ele.RouteIcon}</ListItemIcon> */}
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="Medium" fontSize={14}>
                    {ele.RouteName}
                  </Typography>
                }
              />
            </ListItemButton>
            {i === props.navigationList.length - 1 && <Divider />}
          </List>
        </Collapse>
      );
    } else {
      return null;
    }
  });

  const shouldRenderParent = Boolean(
    childrenList.filter((children) => children).length
  );

  React.useEffect(() => {
    if (isParentCollapsed) setOpen(false);
  }, [isParentCollapsed]);

  if (!shouldRenderParent) return null;

  const handleClick = () => {
    setOpen((prev) => {
      if (!prev) openParentDrawer();
      return !prev;
    });
  };
  const navigateLink = (link) => {
    navigate(link);
  };
  return (
    <React.Fragment>
      <ListItemButton onClick={handleClick} title={props.ListName}>
        <ListItemIcon sx={{ minWidth: 41 }}>{props.NavIcon}</ListItemIcon>
        <ListItemText
          disableTypography
          primary={
            <Typography variant="Medium" fontSize={15}>
              {props.ListName}
            </Typography>
          }
        />
        {open ? (
          <ExpandLess sx={{ color: "text.disabled" }} />
        ) : (
          <ExpandMore sx={{ color: "text.disabled" }} />
        )}
      </ListItemButton>
      {childrenList}
    </React.Fragment>
  );
};

const NavListItem = (props) => (
  <ListItem disablePadding title={props.RouteName}>
    <ListItemButton onClick={() => props.navigateLink(props.RouteLink)}>
      <ListItemIcon sx={{ minWidth: 40 }}>{props.Icon}</ListItemIcon>
      <ListItemText
        disableTypography
        primary={
          <Typography variant="Medium" fontSize={15}>
            {props.RouteName}
          </Typography>
        }
      />
    </ListItemButton>
  </ListItem>
);
const NavBar = () => {
  const { user } = useAuth();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();
  const navigateLink = (link) => {
    navigate(link);
  };

  const commonNavListProps = {
    isParentCollapsed: !open,
    openParentDrawer: handleDrawerOpen,
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="absolute"
        color="transparent"
        open={open}
        sx={{
          boxShadow: "none",
          zIndex: 1000,
        }}
      >
        <Toolbar>
          <AppBarMtg />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader open={open}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              ...(open && { display: "none" }),
              ml: 2,
            }}
          >
            <ChevronRightIcon />
          </IconButton>
          <IconButton
            onClick={handleDrawerClose}
            sx={{
              ...(!open && { display: "none" }),
            }}
          >
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <img
            src="/logo-new.png"
            alt="MTG Logo"
            style={{
              maxHeight: 100,
            }}
          />
        </Grid>
        <Divider />
        <List
          subheader={
            open && (
              <ListSubheader component="div" id="nested-list-subheader">
                <Typography variant="SemiBold" sx={{ color: "text.disabled" }}>
                  MENU
                </Typography>
              </ListSubheader>
            )
          }
          sx={{ paddingTop: 2 }}
        >
          <NavListItem
            Icon={
              <IconComponent>
                <DashboardIcon />
              </IconComponent>
            }
            RouteName="Dashboard"
            RouteLink={`/dashboard/${user.userId}`}
            navigateLink={navigateLink}
          />
          <NavListItem
            Icon={
              <IconComponent>
                <ProfileIcon />
              </IconComponent>
            }
            RouteName="Profile"
            RouteLink={`/member-profile/${user.userId}`}
            navigateLink={navigateLink}
          />
          <NavList
            {...commonNavListProps}
            NavIcon={
              <IconComponent>
                <AccountTreeIcon />
              </IconComponent>
            }
            ListName="Projects"
            navigationList={[
              {
                RouteIcon: <StarBorder />,
                RouteName: "Decision Tracker",
                RouteLink: "/decision-tracker",
                RouteAccess: true,
                // RouteAccess:
                //   user?.role_id === 3 ||
                //   user?.role_id === 6 ||
                //   user?.role_id === 9 ||
                //   user?.role_id === 10 ||
                //   user?.role_id === 11 ||
                //   user?.role_id === 12 ||
                //   user?.role_id === 23,
              },
            ]}
          />
          <NavList
            {...commonNavListProps}
            NavIcon={
              <IconComponent>
                <ReportsIcon />
              </IconComponent>
            }
            ListName="Reports"
            navigationList={[
              {
                RouteIcon: <StarBorder />,
                RouteName: "Forecast Pacing",
                RouteLink: "/forecast",
                RouteAccess: true,
              },
              {
                RouteIcon: <StarBorder />,
                RouteName: "Time Sheet Summary",
                RouteLink: "/time-sheet-summary",
                RouteAccess: true,
              },
              {
                RouteIcon: <StarBorder />,
                RouteName: "Billable Utilization",
                RouteLink: "/billable-utilization",
                RouteAccess: true,
              },
              {
                RouteIcon: <StarBorder />,
                RouteName: "Development Deliverables",
                RouteLink: "/development-deliverables",
                RouteAccess: true,
              },
              {
                RouteIcon: <StarBorder />,
                RouteName: "Project Health Dashboard",
                RouteLink: "/project-health-dashboard",
                RouteAccess: true,
              },
              {
                RouteIcon: <StarBorder />,
                RouteName: "User Certifications",
                RouteLink: "/user-certifications",
                RouteAccess: false,
              },
            ]}
          />

          <NavList
            {...commonNavListProps}
            NavIcon={
              <IconComponent>
                <UpdateIcon />
              </IconComponent>
            }
            ListName="Update Time"
            navigationList={[
              {
                RouteIcon: <StarBorder />,
                RouteName: "Non-billable",
                RouteLink: "/update-non-billable-time",
                RouteAccess:
                  user?.role_id === 3 ||
                  user?.role_id === 6 ||
                  user?.role_id === 9,
              },
            ]}
          />
          <NavList
            {...commonNavListProps}
            navigationList={[
              {
                RouteIcon: <StarBorder />,
                RouteName: "Authenticator Codes",
                RouteLink: "/auth-codes",
                RouteAccess: true,
              },
              {
                RouteIcon: <StarBorder />,
                RouteName: "Add Authenticator",
                RouteLink: "/add-auth",
                RouteAccess: true,
              },
              {
                RouteIcon: <StarBorder />,
                RouteName: "Manual",
                RouteLink: "/manual",
                RouteAccess: true,
              },
            ]}
            NavIcon={
              <IconComponent>
                <AuthentocatorIcon />
              </IconComponent>
            }
            ListName="Authenticator"
          />
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pt: 1,
          maxWidth: open
            ? `calc(100% - ${drawerWidth}px)`
            : `calc(100% - ${minDrawerWidth}px)`,
          // ,overflowX:'scroll'
        }}
      >
        <Toolbar />
        <Grid container pl={3} pt={2} pr={3}>
          <Outlet />
        </Grid>
      </Box>
    </Box>
  );
};

NavList.prototypes = {
  NavIcon: PropTypes.element,
  ListName: PropTypes.string,
  navigationList: PropTypes.arrayOf(
    PropTypes.shape({
      RouteName: PropTypes.string.isRequired,
      RouteIcon: PropTypes.element,
      RouteLink: PropTypes.string.isRequired,
    })
  ),
};

export default NavBar;
