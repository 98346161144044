import { Chip, Divider } from "@mui/material";
import { Stack, Typography } from "../../UIComponents";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useState } from "react";
import Button from "../../UIComponents/DesignSystem/Button";
import { formatDate } from "./index.js";

const TimeRange = ({ dateRange, setDateRange, options, handleClose }) => {
  const [selectedDateRange, setSelectedDateRange] = useState(dateRange);
  const defaultStartDate = dayjs(selectedDateRange.startDate);
  const defaultEndDate = dayjs(selectedDateRange.endDate);
  return (
    <Stack
      gap={2}
      sx={{
        maxWidth: "350px",
        padding: "8px",
      }}
    >
      <Typography fontSize={14}>Since</Typography>
      <Stack justifyContent="start" flexDirection="row" gap={1} flexWrap="wrap">
        {options
          .filter(({ id }) => id.includes("abs"))
          .map((option) => (
            <Chip
              id={option.id}
              label={option.title}
              variant={selectedDateRange.id === option.id ? "" : "outlined"}
              color="primary"
              onClick={() => setSelectedDateRange(option)}
              sx={{
                height: "20px",
              }}
            />
          ))}
      </Stack>
      <Typography fontSize={14}>For</Typography>
      <Stack justifyContent="start" flexDirection="row" gap={1} flexWrap="wrap">
        {options
          .filter(({ id }) => id.includes("ref") && !id.disabled) /** Remove disabled fields too */
          .map((option) => (
            <Chip
              id={option.id}
              label={option.title}
              variant={selectedDateRange.id === option.id ? "" : "outlined"}
              color="primary"
              onClick={() => setSelectedDateRange(option)}
              sx={{
                height: "20px",
              }}
              // disabled={option.disabled}
            />
          ))}
      </Stack>

      <Typography fontSize={14}>Other</Typography>
      <Stack justifyContent="start" flexDirection="row" gap={1} flexWrap="wrap">
        {options
          .filter(({ id }) => id.includes("custom"))
          .map((option) => (
            <Chip
              id={option.id}
              label={option.title}
              variant={selectedDateRange.id === option.id ? "" : "outlined"}
              color="primary"
              onClick={() => setSelectedDateRange(option)}
              sx={{
                height: "20px",
              }}
            />
          ))}
      </Stack>
      {selectedDateRange.title === "Custom" && (
        <>
          <Divider />
          <Stack gap={1}>
            <Typography fontSize={14}>Custom</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack gap={1} flexDirection="row">
                <DatePicker
                  label="Start Date"
                  defaultValue={defaultStartDate}
                  value={defaultStartDate}
                  onChange={(newValue) =>
                    setSelectedDateRange((prev) => ({
                      ...prev,
                      startDate: formatDate(newValue),
                    }))
                  }
                />
                <DatePicker
                  label="End Date"
                  defaultValue={defaultEndDate}
                  value={defaultEndDate}
                  onChange={(newValue) =>
                    setSelectedDateRange((prev) => ({
                      ...prev,
                      endDate: formatDate(newValue),
                    }))
                  }
                />
              </Stack>
            </LocalizationProvider>
          </Stack>
        </>
      )}

      <Stack justifyContent="end" flexDirection="row" gap={1}>
        <Button
          color="primaryLight"
          variant="contained"
          size="small"
          onClick={handleClose}
        >
          <Typography>Close</Typography>
        </Button>
        <Button
          color="secondary"
          variant="contained"
          size="small"
          onClick={() => {
            setDateRange(selectedDateRange);
            handleClose();
          }}
        >
          <Typography>Save</Typography>
        </Button>
      </Stack>
    </Stack>
  );
};

export default TimeRange;
